export const routes = {
  onboarding: "/onboarding",
  signup: "/onboarding/signup",
  login: "/login",
  callback: "/callback",
  forgotPassword: "/forgot-password",
  forgotPasswordConfirmation: "/forgot-password-confirmation",
  createPassword: "/onboarding/create-password",
  changePassword: "/change-password",
  userName: "/onboarding/name",
  userDob: "/onboarding/dob",
  addPhoto: "/onboarding/profile-photo",
  userBio: "/onboarding/bio",
  campaign: "/campaign",
  campaignComplete: "/campaign/game-complete",
  campaignDetails: "/campaign/details",
  campaignThankYou: "/campaign/thank-you",
  privacy: "/privacy",
  terms: "/terms",
  bubblePop: "/campaign/bubble-pop",
  colink: "/personalize-stashlink",
  error: "/error",
  pageNotFound: "/page-not-found"
};
