import { useEffect, useRef, useState, useCallback } from "react";
import { useHistory, useLocation } from "react-router";
import { getLocalStorage } from "../../../utils/common-function";
import { Campaign, SampleEligibleBrandsData, Stashlink, User } from "../../../types";
import { routes } from "../../../routes/routes.constant";
import useAuthStatus from "./useAuthStatus";
import { sevenSecondTimer } from "../../../common/constants";

const useThankYouLogic = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const parentVideoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const navigation = useHistory();
  const { isUserLoggedIn } = useAuthStatus();
  const userData = getLocalStorage("userData") as User;
  const brandsData = getLocalStorage("brandsData") as SampleEligibleBrandsData;
  const campaign = getLocalStorage("campaign") as Campaign;
  const stashlink = getLocalStorage("stashlink") as Stashlink;
  const [showParentVedio, setShowParentVedio] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [parentVideoProgress, setParentVideoProgress] = useState(0);
  const [muted, setMuted] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const state = location.state as { fromValidFlow: boolean };
    if (!state?.fromValidFlow) {
      const stashLink = getLocalStorage("stashLink");
      navigation.push(stashLink ? stashLink : "/");
    }
  }, [location.state, navigation]);

  const handleTimeUpdate = useCallback(() => {
    if (videoRef.current) {
      const progress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setVideoProgress(progress);
      if (progress === 100 && stashlink?.parentStashlink) {
        setShowParentVedio(true);
        parentVideoRef.current?.play();
      }
    }
  }, [stashlink]);

  const handleParentTimeUpdate = () => {
    if (parentVideoRef.current) {
      const progress = (parentVideoRef.current.currentTime / parentVideoRef.current.duration) * 100;
      setParentVideoProgress(progress);
    }
  };

  const handleVideoEnd = () => {
    if (!stashlink?.parentStashlink?.thankYouMessageVideo) {
      setIsPlaying(false);
    }
  };

  const handleParentVideoEnd = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    videoRef.current?.addEventListener("timeupdate", handleTimeUpdate);
    videoRef.current?.addEventListener("ended", handleVideoEnd);

    parentVideoRef.current?.addEventListener("timeupdate", handleParentTimeUpdate);
    parentVideoRef.current?.addEventListener("ended", handleParentVideoEnd);

    return () => {
      videoRef.current?.removeEventListener("timeupdate", handleTimeUpdate);
      videoRef.current?.removeEventListener("ended", handleVideoEnd);
      parentVideoRef.current?.removeEventListener("timeupdate", handleParentTimeUpdate);
      parentVideoRef.current?.removeEventListener("ended", handleParentVideoEnd);
    };
  }, [handleTimeUpdate, handleVideoEnd, handleParentTimeUpdate, handleParentVideoEnd]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (!stashlink?.thankYouMessageVideo && !showParentVedio) {
      setVideoProgress(0);
      timer = setInterval(() => {
        setVideoProgress((prev) => {
          if (prev < 100) {
            return prev + sevenSecondTimer; // Keep incrementing until 100
          } else {
            setShowParentVedio(true);
            clearInterval(timer); // Clear the timer once 100 is reached
            return 100;
          }
        });
      }, 1000);
    }

    if (showParentVedio && !stashlink?.parentStashlink?.thankYouMessageVideo) {
      setParentVideoProgress(100);
    }

    return () => clearInterval(timer); // Cleanup when the component unmounts or dependencies change
  }, [stashlink, showParentVedio]);

  const handlePlayPause = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
      } else {
        videoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleParentPlayPause = () => {
    if (parentVideoRef.current) {
      if (parentVideoRef.current.paused) {
        parentVideoRef.current.play();
        setIsPlaying(true);
      } else {
        parentVideoRef.current.pause();
        setIsPlaying(false);
      }
    }
  };

  const navigateToColink = () => {
    isUserLoggedIn
      ? navigation.push({ pathname: routes.callback, state: { from: routes.colink } })
      : navigation.push({
          pathname: routes.onboarding,
          state: {
            from: routes.colink,
            param: { isNavigateBack: true },
            userName: stashlink.parentStashlink ? stashlink.parentStashlink.user.fullName : stashlink.user.fullName,
            followerProfileImage: stashlink.parentStashlink ? stashlink.parentStashlink.user.profilePhoto : stashlink.user.profilePhoto
          }
        });
  };

  const navigateToStashAgain = () => {
    navigation.replace(`/stashlink/${stashlink.id}`);
  };

  return {
    videoRef,
    parentVideoRef,
    isPlaying,
    stashlink,
    handlePlayPause,
    navigateToColink,
    navigateToStashAgain,
    handleParentPlayPause,
    setShowParentVedio,
    campaign,
    userData,
    brandsData,
    showParentVedio,
    videoProgress,
    parentVideoProgress,
    muted,
    setMuted
  };
};

export default useThankYouLogic;
