import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { useAuth } from "../../../../store/contextAPI/authContext";
import { useMutation, useQuery } from "@apollo/client";
import { supabaseClient } from "../../../../services/supabase/supabaseClient";
import { SEARCH_USER_BY_EMAIL, SYNC_SUPABASE_USER } from "../../../../queries";
import { getLocalStorage, getSupabaseSession, setCookie, setLocalStorage } from "../../../../utils/common-function";
import useUser from "../../../campaign/hooks/useUser";
import { routes } from "../../../../routes/routes.constant";
import { ILocationState } from "./types";

const useRedirectCallback = () => {
  const history = useHistory();
  const location = useLocation<ILocationState>();
  const { userData, fetchUser } = useUser();
  const userEmail = getLocalStorage("email");
  const [isLoading, setLoading] = useState(true);
  const [userDetailsFetched, setUserDetailsFetched] = useState(false);
  const { setProfileComplete, setEmailContext, setSSOErrorContext, setNameContext, setPasswordContext } = useAuth();
  const [syncSupabaseUser] = useMutation(SYNC_SUPABASE_USER);
  const {
    data,
    error,
    refetch: searchUserByEmail
  } = useQuery(SEARCH_USER_BY_EMAIL, {
    variables: { email: userEmail },
    fetchPolicy: "network-only"
  });

  const handleSyncUser = useCallback(async () => {
    try {
      const result = await syncSupabaseUser({
        variables: { input: {} }
      });
      const userId = result.data.syncSupabaseUser.user.id;
      setLocalStorage("userId", userId);
      await fetchUser({ variables: { userId } });
    } catch (e) {
      console.error("Error syncing user:", e);
    }
  }, [syncSupabaseUser, fetchUser]);

  const getUserDetails = useCallback(async () => {
    if (userDetailsFetched) return;

    try {
      const {
        data: { user }
      } = await supabaseClient.auth.getUser();
      if (user?.user_metadata.email) {
        setEmailContext(user.user_metadata.email);
        searchUserByEmail({ email: user.user_metadata.email });
      }
      setUserDetailsFetched(true);
    } catch (e) {
      console.error("Error fetching user details:", e);
    }
  }, [searchUserByEmail, setEmailContext, userDetailsFetched]);

  const prefillUserDetails = useCallback(
    (email: string, name: string) => {
      const [firstName, lastName = ""] = name.trim().split(" ");
      setPasswordContext("");
      setLocalStorage("email", email);
      setNameContext(firstName, lastName);
    },
    [setNameContext, setPasswordContext]
  );

  const handleRedirectCallback = useCallback(async () => {
    const session = await getSupabaseSession();
    if (session) {
      setCookie("token", session.access_token);
      const { email, name } = session.user.user_metadata;
      if (name) prefillUserDetails(email, name);
      setLoading(false);
    } else {
      history.push({ pathname: routes.onboarding, state: { from: location.state?.from } });
    }
  }, [history, prefillUserDetails]);

  useEffect(() => {
    handleRedirectCallback();
  }, [handleRedirectCallback]);

  useEffect(() => {
    if (!userDetailsFetched) {
      getUserDetails();
    }
  }, [getUserDetails, userDetailsFetched]);

  useEffect(() => {
    if (data) {
      handleSyncUser();
      if (!data.searchByEmail) {
        history.push(routes.userName);
      } else {
        if (userData?.user?.id) {
          const { fullName, birthdate, profilePhoto } = userData.user;
          if (!fullName) {
            history.push({ pathname: routes.userName, state: location.state });
          } else if (!birthdate) {
            history.push({ pathname: routes.userDob, state: location.state });
          } else if (!profilePhoto) {
            history.push({ pathname: routes.addPhoto, state: location.state });
          } else {
            setProfileComplete();
            history.push({ pathname: location.state?.from ?? "/", state: location.state });
          }
        }
      }
    }
  }, [data, handleSyncUser, history, userData, history, location, setProfileComplete]);

  useEffect(() => {
    if (error) {
      setSSOErrorContext(true);
      history.push(routes.signup);
    }
  }, [error, setSSOErrorContext, history]);

  return {
    isLoading
  };
};

export default useRedirectCallback;
