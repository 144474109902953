import { useCallback, useEffect, useState } from "react";
import { getCookie, setLocalStorage } from "../../utils/common-function";
import { IProfileMenu } from "./types";

const useProfileMenu = (props: IProfileMenu) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);

  const handleLogoutClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onConfirm = useCallback(() => {
    setIsModalOpen(false);
    setLocalStorage("impactScoreLevel", "");
    props.onClose();
  }, [props.onClose]);

  useEffect(() => {
    const token = getCookie("token");
    if (!token) {
      setIsUserLoggedIn(false);
    } else {
      setIsUserLoggedIn(true);
    }
  }, []);

  return {
    isUserLoggedIn,
    isModalOpen,
    onConfirm,
    handleLogoutClick,
    setIsModalOpen
  };
};

export default useProfileMenu;
