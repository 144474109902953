const LinkedInIcon = ({ color = "#FAFAFA", width = "28", height = "29", className = "" }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.5" y="3.5" width="21" height="21" rx="5.83333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M9.58724 13.127V19.427" stroke={color} strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M13.7861 19.4265V15.7515C13.7861 14.3013 14.961 13.1265 16.4111 13.1265V13.1265C17.8613 13.1265 19.0361 14.3013 19.0361 15.7515V19.4265"
      stroke={color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.58217 9.32083C9.4375 9.32083 9.31967 9.43867 9.32084 9.58333C9.32084 9.728 9.43867 9.84583 9.58334 9.84583C9.728 9.84583 9.84584 9.728 9.84584 9.58333C9.84584 9.4375 9.728 9.32083 9.58217 9.32083"
      stroke={color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkedInIcon;
