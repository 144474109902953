export const Constants = {
  sizeMap: {
    xs: "7",
    s: "12",
    m: "20",
    l: "32",
    xl: "40"
  }
};

export const inputType = {
  text: "text",
  password: "password",
  file: "file"
};

export const emptyBubble = [16, 17, 18, 23, 24, 25, 30, 31, 32];
export const gameDefaultAmounts = [0.01, 0.05, 0.1, 0.2, 0.25, 0.5];
export const oneDollar = "1.00";
export const gameType = {
  bubblePop: "bubble_pop"
};

export const timeout = {
  halfSecond: 500,
  twoThousand: 2000,
  fourThousand: 4000,
  sixThousand: 6000,
  twoThousandSixHundred: 2600
};

export const sevenSecondTimer = 14.29;

export const envVariables = {
  apiEndPoint: "https://api.stashrun.com/graphql",
  domainUrl: "https://app.stashrun.com",
  supabaseUrl: "https://login.stashrun.com",
  supabaseAnonKey:
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InB3cWhpcHZ1d2xqdWVqdmJrZ2VxIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ4Mjc2NTYsImV4cCI6MjA0MDQwMzY1Nn0.yfli5Qu9Qh-HFOIwbAx4JZsI_Vpy8rEjzYMdNhzpz7g"
};
