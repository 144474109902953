const TikTokIcon = ({ color = "#FAFAFA", width = "28", height = "29", className = "" }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.5" y="4.30811" width="21" height="21" rx="5.83333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.2507 14.2248H11.9647C10.5139 14.2243 9.33674 15.3989 9.33398 16.8498H9.33398C9.33398 18.6217 10.7704 20.0581 12.5423 20.0581V20.0581C14.3142 20.0581 15.7507 18.6217 15.7507 16.8498V9.55811"
      stroke={color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.75 9.55811C15.75 11.1689 17.0558 12.4748 18.6667 12.4748"
      stroke={color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TikTokIcon;
