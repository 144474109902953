import React from "react";
import { convertToPercentage } from "../../utils/common-function";
import { CampaignCardProps } from "./campaignCard.types";
import { useHistory } from "react-router-dom";
import { formatCurrencyToUsd } from "../../utils/format";
import ProgressBar from "../common/progressBar";

const CampaignCard: React.FC<CampaignCardProps> = (props: CampaignCardProps) => {
  const history = useHistory();
  const percentageCompleted = convertToPercentage(props.campaign.currentAmount, props.campaign.goalAmount);
  // This is temporary function will replace by hook in future
  const navigateFunction = (id?: string) => {
    history.push(`/campaign/${id}`);
  };
  return (
    <div
      className="relative mb-8 bg-primary-l7/20 rounded-xl overflow-hidden shadow-custom shadow-black min-w-[300px]"
      onClick={() => navigateFunction(props.campaign?.id)}>
      <img src={props.campaign.imageUrl} alt={props.campaign.title} className="w-full h-38 object-cover" />
      <img
        src={props.campaign.charity?.imageUrl}
        alt="Logo"
        className="absolute -top-2 -left-2 w-20 h-20 rounded-full border-8 border-modal-top-bg"
      />
      <div className="p-4">
        <h3 className="text-2xl font-primary text-base-white font-bold">{props.campaign.title}</h3>
        <p className="text-lg text-grey-op30">{props.campaign.description}</p>
        <div className="mt-2">
          <ProgressBar percentageCompleted={percentageCompleted} />
          <p className="text-2xl text-base-white font-primary font-medium mt-2">
            ${formatCurrencyToUsd(props.campaign?.currentAmount)}{" "}
            <span className="font-secondary text-base font-normal">raised of {formatCurrencyToUsd(props.campaign.goalAmount)}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CampaignCard;
