import React, { useState } from "react";
import Button from "../../components/common/button";
import Loader from "../../components/common/loader/loader";
import useThankYouLogic from "./hooks/useThankYou";
import ShareComponent from "../../components/shareModal/shareModal";
import VolumeMute from "./../../assets/images/Mute.svg";
import VolumeFull from "./../../assets/images/volume-full.svg";
import useColinkExist from "../../services/hooks/useColinkExist";
import FollowingCheck from "../../assets/images/following-check.svg";
import { generateCloudinaryImageUrl, generateCloudinaryVideoUrl, generateProfileImageUrl, getLocalStorage } from "../../utils/common-function";

const ThankYou: React.FC = () => {
  const {
    videoRef,
    stashlink,
    navigateToColink,
    navigateToStashAgain,
    setShowParentVedio,
    parentVideoRef,
    userData,
    brandsData,
    campaign,
    showParentVedio,
    parentVideoProgress,
    videoProgress,
    muted,
    setMuted
  } = useThankYouLogic();
  const { colinkExistData } = useColinkExist(stashlink?.id || "");
  const [isLoading, setIsLoading] = useState(true);
  const [isColinkVideoLoading, setIsColinkVideoLoading] = useState(true);

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const userId = getLocalStorage("userId") as string;
  
  const colinkVideoUrl = stashlink.thankYouMessageVideo?.replace(".mkv", ".mp4") ?? "";
  const parentVideoUrl = stashlink.parentStashlink?.thankYouMessageVideo?.replace(".mkv", ".mp4") ?? "";

  return !stashlink ? (
    <div className="h-[calc(100vh-48px)] w-screen flex justify-center items-center bg-soft-black">
      <Loader />
    </div>
  ) : (
    <div className="relative h-[calc(100vh-146px)] w-screen flex flex-col items-center pt-8">
      <div className={`absolute top-2 w-[calc(100vw-32px)] ${stashlink.parentStashlink && "grid grid-cols-2 gap-2"}`}>
        <div onClick={() => setShowParentVedio(false)} className="relative bg-grey w-full h-0.5 rounded-xl overflow-hidden z-10">
          <div className="bg-base-white absolute left-0 h-0.5" style={{ width: `${videoProgress}%` }}></div>
        </div>
        {stashlink.parentStashlink && (
          <div onClick={() => setShowParentVedio(true)} className="relative bg-grey w-full h-0.5 rounded-xl overflow-hidden z-10">
            <div className="bg-base-white absolute left-0 h-0.5" style={{ width: `${parentVideoProgress}%` }}></div>
          </div>
        )}
      </div>

      <h1 className="text-primary-l1 font-primary text-2xl z-10 [text-shadow:_2px_2px_0_rgb(0_0_0_/_60%)]">Thank You!</h1>

      <div className="absolute top-7 left-4 w-9 h-37 bg-primary-l1 rounded-full z-10">
        <img
          src={
            showParentVedio
              ? generateProfileImageUrl(stashlink.parentStashlink?.user.profilePhoto || "", "65", "65")
              : generateProfileImageUrl(stashlink.user.profilePhoto || "", "65", "65")
          }
          alt={showParentVedio ? stashlink.parentStashlink?.user.fullName : stashlink.user.fullName}
          className="w-33 h-33 absolute left-2/4 -translate-x-2/4 bottom-px rounded-full object-cover"
        />
      </div>
      {((!showParentVedio && stashlink.thankYouMessageVideo) || (showParentVedio && stashlink.parentStashlink?.thankYouMessageVideo)) && (
        <div className="absolute top-8 right-4 bg-transparent/10 rounded-full z-10 h-8 w-8 flex items-center justify-center backdrop-filter-blur">
          <img
            src={muted ? VolumeMute : VolumeFull}
            alt={VolumeFull}
            onClick={(event) => {
              event.stopPropagation();
              setMuted(!muted);
            }}
          />
        </div>
      )}

      {!showParentVedio &&
        (stashlink.thankYouMessageVideo ? (
          <div className="absolute top-0 left-0 w-full h-full">
            {isLoading && (
              <div className="absolute inset-0 flex items-center justify-center z-10">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
              </div>
            )}
            <video
              muted={muted}
              ref={videoRef}
              src={generateCloudinaryVideoUrl(colinkVideoUrl, "750", "930")}
              className={`object-cover w-full h-full ${isLoading ? "opacity-0" : "opacity-100"} transition-opacity duration-500`}
              autoPlay
              loop
              playsInline
              onLoadedData={() => setIsLoading(false)}
            />
            <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-t from-soft-black/0 from-30% via-soft-black/0 via-90% to-soft-black/60 to-100%"></div>
          </div>
        ) : (
          <div className="absolute top-0 left-0 w-full h-full">
            <div>
              <img
                src={generateCloudinaryImageUrl(stashlink.user.profilePhoto ?? "", "750", "930")}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full object-cover"
              />
              <div className="absolute bottom-20 w-full h-full top-0 bg-gradient-to-t from-black from-5% via-black/70 via-20% to-black/1 to-90%">
                <p className="w-full break-all absolute bottom-20 left-0 p-5 text-center font-secondary text-base leading-tight-19.2 text-base-white">
                  {stashlink.thankYouMessage}
                </p>
              </div>
            </div>
          </div>
        ))}

      {showParentVedio &&
        (stashlink.parentStashlink?.thankYouMessageVideo && showParentVedio ? (
          <div className="absolute top-0 left-0 w-full h-full">
            {isColinkVideoLoading && (
              <div className="absolute inset-0 flex items-center justify-center z-10">
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12"></div>
              </div>
            )}
            <video
              muted={muted}
              ref={parentVideoRef}
              src={generateCloudinaryVideoUrl(parentVideoUrl, "750", "930")}
              className={`object-cover w-full h-full ${isColinkVideoLoading ? "opacity-0" : "opacity-100"} transition-opacity duration-500`}
              playsInline
              autoPlay
              loop
              onLoadedData={() => setIsColinkVideoLoading(false)}
            />
            <div className="absolute w-full h-full top-0 left-0 bg-gradient-to-t from-soft-black/0 from-30% via-soft-black/0 via-90% to-soft-black/60 to-100%"></div>
          </div>
        ) : (
          <div className="absolute top-0 left-0 w-full h-full">
            <div>
              <img
                src={generateCloudinaryImageUrl(stashlink.parentStashlink?.user.profilePhoto || "", "750", "930")}
                className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full h-full object-cover"
              />
              <div className="absolute bottom-20 w-full h-full top-0 bg-gradient-to-t from-black from-5% via-black/70 via-20% to-black/1 to-90%">
                <p className="w-full break-all absolute bottom-20 left-0 p-5 text-center font-secondary text-base leading-tight-19.2 text-base-white">
                  {stashlink.parentStashlink?.thankYouMessage}
                </p>
              </div>
            </div>
          </div>
        ))}

      <div className="w-screen absolute bottom-0">
        <div className="fixed bottom-0 w-full flex flex-col z-10 items-center justify-center bg-gradient-to-t from-soft-black from-50% via-soft-black/70 via-80% to-soft-black/1 to-100% pt-8">
          <div className="w-full flex gap-3">
            <div className="w-full px-12">
              {(colinkExistData || stashlink.user.id === userId) ? (
                <div className="w-full mt-2 p-4">
                  <button className="w-full px-4 py-3 text-base font-primary tracking-wider font-wght-750 rounded-lg text-grey bg-grey-bg border border-grey flex align-center justify-center">
                    Teamed Up{" "}
                    <span className="ml-1">
                      <img src={FollowingCheck} alt="Following Check"></img>
                    </span>
                  </button>
                </div>
              ) : (
                <Button isDisabled={false} title="Team up" onClick={navigateToColink} />
              )}
            </div>
          </div>

          <p className="mt-2 font-primary font-medium text-neutral-50 text-sm mb-14px leading-tight-16.8 ">
            with{" "}
            <span className="font-primary font-bold tracking-wide">
              {showParentVedio ? stashlink.parentStashlink?.user.fullName : stashlink.user.fullName}
            </span>
          </p>
          <div className="bg-black/70 grid grid-cols-2 gap-4 p-4 py-3 w-full backdrop-blur-8">
            <div>
              <button
                onClick={() => setIsShareModalOpen(!isShareModalOpen)}
                className="w-full p-3 bg-grey/20 rounded-lg h-11 border border-[#90a8c1]/70 tracking-wide text-neutral-50 text-center text-sm font-primary font-bold leading-tight-16.8">
                Share stashlink
              </button>
            </div>
            <div>
              <button
                onClick={navigateToStashAgain}
                className="w-full p-3 bg-grey/20 rounded-lg h-11 border border-[#90a8c1]/70 tracking-wide text-neutral-50 text-center text-sm font-primary font-bold leading-tight-16.8">
                Play again
              </button>
            </div>
          </div>
        </div>
      </div>
      {isShareModalOpen && (
        <ShareComponent
          stashUser={stashlink.parentStashlink?.user || userData}
          coLinkUser={stashlink.parentStashlink?.user ? userData : undefined}
          charityName={campaign.charity?.name}
          charityLogo={campaign.charity?.imageUrl}
          currentAmount={campaign.currentAmount}
          goalAmount={campaign.goalAmount}
          shareUrl={`${userData.id}/${brandsData.sampleEligibleBrands?.campaign.id}`}
          isOpen={isShareModalOpen}
          onClose={() => setIsShareModalOpen(!isShareModalOpen)}
        />
      )}
    </div>
  );
};

export default ThankYou;
