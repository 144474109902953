import { RootAppComponentWithRoutes, RootRouteComponentWithSubRoutes } from "./routeWrapper";
import { routes } from "../routes/routes.constant";
import Onboarding from "../modules/Accounts/Onboarding/onboarding";
import SetYourPassword from "../modules/Accounts/Onboarding/managePassword";
import UserName from "../modules/Accounts/Onboarding/username";
import Dob from "../modules/Accounts/Onboarding/dob";
import AddPhoto from "../modules/Accounts/Onboarding/addPhoto";
import Bio from "../modules/Accounts/Onboarding/bio";
import Login from "../modules/Accounts/Login/enterPassword";
import ForgotPassword from "../modules/Accounts/Login/forgotPassword";
import ForgotPasswordConfirmation from "../modules/Accounts/Login/forgotPasswordConfirmation";
import CreatePassword from "../modules/Accounts/Login/createPassword";
import ChangePassword from "../modules/Accounts/Login/changePassword";
import CampaignProfile from "../modules/campaign/campaignProfile";
import CelebrityList from "../modules/home/celebrityList";
import CelebrityCampaigns from "../modules/home/celebrityCampaigns";
import HeadToHead from "../modules/home/headToHead";
import ThankYou from "../modules/campaign/thankYou";
import GameComplete from "../modules/campaign/gameComplete";
import ColinkCreation from "../modules/coLink/colinkCreation";
import ColinkConfirmation from "../modules/coLink/colinkConfirmation";
import PrivacyPolicy from "../modules/termsAndPrivacy/privacyPolicy";
import TermsOfService from "../modules/termsAndPrivacy/termsOfService";
import RedirectCallback from "../modules/Accounts/Onboarding/redirectCallback";
import BubblePopGame from "../modules/campaign/game/bubblePopGame";
import SponsorDetails from "../modules/campaign/sponsorDetails";
import StaticHomePage from "../modules/home/staticHomePage";
import CaptureVideo from "../components/common/captureVideo/captureVideo";
import ErrorScreen from "../components/common/errorScreen";
import NotFound from "../components/common/notFound";
import GameDisable from "../components/gameDisable/gameDisable";

// ROUTES CONFIG
const ROUTES = [
  {
    key: "CAMPAIGN",
    path: "/campaign",
    title: "Campaign",
    component: RootAppComponentWithRoutes,
    showInMainNav: "skip",
    routes: [
      {
        key: "CAMPAIGN_DETAILS",
        path: routes.campaignDetails,
        title: "Campaign details",
        component: SponsorDetails,
        exact: true
      },
      {
        key: "GAME_COMPLETE",
        path: routes.campaignComplete,
        title: "Game Complete",
        component: GameComplete,
        exact: true
      },
      {
        key: "THANK_YOU",
        path: routes.campaignThankYou,
        title: "Thank You",
        component: ThankYou,
        exact: true
      },
      {
        key: "GAME",
        path: routes.bubblePop,
        title: "Game",
        component: BubblePopGame,
        exact: true,
        featureFlag: "campaign-wall",
        redirectComponent: GameDisable,
      }
    ]
  },
  {
    key: "ONBOARDING",
    path: routes.onboarding,
    title: "Onboarding",
    component: RootAppComponentWithRoutes,
    showInMainNav: "skip",
    routes: [
      {
        key: "ONBOARDING",
        path: routes.onboarding,
        title: "Onboarding",
        component: Onboarding,
        exact: true
      },
      {
        key: "SIGNUP",
        path: routes.signup,
        title: "signup",
        component: SetYourPassword,
        exact: true
      },
      {
        key: "USERNAME",
        path: routes.userName,
        title: "Enter your name",
        component: UserName,
        exact: true
      },
      {
        key: "DOB",
        path: routes.userDob,
        title: "Enter DOB",
        component: Dob,
        exact: true
      },
      {
        key: "ADD PHOTO",
        path: routes.addPhoto,
        title: "Add Photo",
        component: AddPhoto,
        exact: true
      },
      {
        key: "LOOKS GOOD",
        path: routes.userBio,
        title: "Looks good",
        component: Bio,
        exact: true
      },
      {
        key: "CREATE_PASSWORD",
        path: routes.createPassword,
        title: "Create password",
        component: CreatePassword,
        exact: true
      }
    ]
  },
  {
    /* APP */
    key: "APP",
    path: "/",
    title: "App",
    component: RootAppComponentWithRoutes,
    showInMainNav: "skip",
    routes: [
      {
        key: "APP_ROOT",
        path: "/",
        title: "Home",
        component: StaticHomePage,
        icon: "home",
        exact: true,
        showInMainNav: true
      },
      {
        key: "CALLBACK",
        path: routes.callback,
        title: "Callback",
        component: RedirectCallback,
        icon: "callback",
        exact: true,
        showInMainNav: true
      },
      {
        key: "LOGIN",
        path: routes.login,
        title: "Login",
        component: Login,
        showInMainNav: true,
        routes: []
      },
      {
        key: "FORGOT_PASSWORD",
        path: routes.forgotPassword,
        title: "Forgot password",
        component: ForgotPassword,
        showInMainNav: true,
        routes: []
      },
      {
        key: "FORGOT_PASSWORD_CONFIRMATION",
        path: routes.forgotPasswordConfirmation,
        title: "Forgot Password Confirmation",
        component: ForgotPasswordConfirmation,
        showInMainNav: true,
        routes: []
      },
      {
        key: "CHANGE_PASSWORD",
        path: routes.changePassword,
        title: "Change password",
        component: ChangePassword,
        exact: true
      },
      {
        key: "Celebrities",
        path: "/celebrities",
        title: "Fundraise With Celebrity",
        component: CelebrityList,
        exact: true,
        showInMainNav: true
      },
      {
        key: "UserCampaign",
        path: "/users/:userId/campaigns",
        title: "Celebrity Stash Link",
        component: CelebrityCampaigns,
        exact: true,
        showInMainNav: true
      },
      {
        key: "CampaignProfile",
        path: "/campaign/:campaignId",
        title: "Celebrity Campaign Profile",
        component: CampaignProfile,
        exact: true,
        showInMainNav: true
      },
      {
        key: "HeadToHead",
        path: "/headToHead",
        title: "Head To Head",
        component: HeadToHead,
        exact: true,
        showInMainNav: true
      },
      {
        key: "SIGNUP",
        path: "/signup",
        title: "Signup",
        component: RootRouteComponentWithSubRoutes,
        showInMainNav: true,
        icon: "smile",
        routes: []
      },
      {
        key: "COLINK",
        path: "/personalize-stashlink",
        title: "Create CoLink",
        component: ColinkCreation,
        exact: true
      },
      {
        key: "CAPTURE_VIDEO",
        path: "/capture-video",
        title: "Capture Video",
        component: CaptureVideo,
        exact: true
      },
      {
        key: "COLINK_CONFIRMATION",
        path: "/create-stashlink",
        title: "Confirm Colink",
        component: ColinkConfirmation,
        exact: true
      },
      {
        key: "PRIVACY POLICY",
        path: routes.privacy,
        title: "Privacy Policy",
        component: PrivacyPolicy,
        showInMainNav: true,
        routes: []
      },
      {
        key: "TERMS OF SERVICE",
        path: routes.terms,
        title: "Terms Of Service",
        component: TermsOfService,
        exact: true
      },
      {
        key: "STASH",
        path: "/stashlink/:stashlinkId",
        title: "Stash",
        component: CampaignProfile,
        exact: true
      }
    ]
  },
  {
    key: "ERROR_SCREEN",
    path: routes.error,
    title: "Error Screen",
    component: ErrorScreen,
    showInMainNav: "skip"
  },
  {
    key: "PAGE NOT FOUND",
    path: routes.pageNotFound,
    title: "Page Not Found",
    component: NotFound
  }
];

export default ROUTES;
