import React from "react";
import { ColinkUsersCardProps } from "./colinkUsersCard.types";
import { PiShareNetworkBold } from "react-icons/pi";
import { generateCloudinaryImageUrl, getStyles } from "../../utils/common-function";
import VerifiedIcon from "../svgImages/verifiedIcon";
import preview from "../../assets/images/preview.png";

const ColinkUsersCard: React.FC<ColinkUsersCardProps> = (props: ColinkUsersCardProps) => {
  const { parentUser, colinkUser, isShareIconRequired } = props;
  const openShareModal = () => {
    props.setIsShareModalOpen && props.setIsShareModalOpen(true);
  };

  const styles = getStyles(props.impactScoreLevel);

  return (
    <div className="relative w-full flex flex-col items-center text-center">
      <div className="relative w-full h-229  flex justify-center items-center grid grid-cols-2">
        {!isShareIconRequired && (
          <div className="absolute w-full top-3 flex justify-center z-10">
            <img src={preview} alt="Preview" className="shadow-lg" />
          </div>
        )}
        <div className="relative h-229  border-r-2 border-black">
          <img src={generateCloudinaryImageUrl(parentUser?.profilePhoto || "", "370","458")} alt={parentUser?.fullName} className="h-229 w-full object-cover" />
        </div>
        <div className="relative h-229 border-l-2 border-black">
          <img src={generateCloudinaryImageUrl(colinkUser?.profilePhoto || "", "370","458")} alt={colinkUser?.fullName} className="h-229 w-full object-cover" />
        </div>
      </div>
      {isShareIconRequired && (
        <PiShareNetworkBold
          className="text-white text-28px bg-black/10 rounded-full p-2 z-10 absolute top-3 right-3 backdrop-blur-43.20"
          onClick={() => openShareModal()}
        />
      )}

      <div
        className="w-full bg-gradient-to-t from-black from-50% via-black/70 via-80% to-black/0 to-100% -mt-12 z-10"
        style={{ color: styles.levelColor }}>
        <p className="w-full text-xl px-6 text-center font-primary font-extrabold flex justify-center items-center inline-block">
          {parentUser?.fullName}{" "}
          {parentUser?.isPublicProfile && (
            <VerifiedIcon color={styles.levelColor} className="ml-0.5 w-4 h-4 inline-block align-middle relative -top-2" />
          )}
        </p>
        <p className="text-xl text-center font-primary font-extrabold -mt-2 -mb-2">
          <strong>X</strong>
        </p>
        <p className="w-full text-xl px-2 text-center font-primary font-extrabold flex justify-center items-center inline-block">
          {colinkUser?.fullName}{" "}
          {colinkUser?.isPublicProfile && (
            <VerifiedIcon color={styles.levelColor} className="ml-0.5 w-4 h-4 inline-block align-middle relative -top-2" />
          )}
        </p>
      </div>
    </div>
  );
};

export default ColinkUsersCard;
