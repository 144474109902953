import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import useCampaign from "./useCampaign";
import { routes } from "../../../routes/routes.constant";
import { timeout } from "../../../common/constants";
import { getLocalStorage, setLocalStorage } from "../../../utils/common-function";
import { v4 as uuid } from "uuid";
import useStashLinkHooks from "./useStashLink";

const useCampaignProfile = () => {
  const { stashlinkId } = useParams<{ stashlinkId: string }>();
  const { getStashLink, stashlink, stashlinkLoading } = useStashLinkHooks();
  const {
    eligibleBrandsData,
    fetchEligibleBrands,
    eligibleBrandsLoading,
    fetchCampaignAttempts,
    campaignAttemptsData,
    campaignAttemptsLoading
  } = useCampaign();
  const [isMatchSponsor, setIsMatchSponsor] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const navigation = useHistory();
  const user = stashlink?.user;
  const campaign = stashlink?.campaign;

  const fetchBrands = () => {
    fetchEligibleBrands({
      variables: {
        campaignId: campaign?.id,
        userIdentifier: currentUserId
      }
    });
    setIsMatchSponsor(!isMatchSponsor);
  };

  useEffect(() => {
    if (eligibleBrandsData) {
      setLocalStorage("brandsData", eligibleBrandsData);
      setLocalStorage("userData", user);
      setLocalStorage("campaign", campaign);
      setLocalStorage("stashLink", `/stashlink/${stashlinkId}`);

      const timer = setTimeout(() => {
        navigation.push(routes.campaignDetails, { fromValidFlow: true });
      }, timeout.fourThousand);

      return () => clearTimeout(timer);
    }
  }, [eligibleBrandsData]);

  useEffect(() => {
    const loggedInUserId = getLocalStorage("userId");
    const generatedUserId = getLocalStorage("generatedUserId");

    if (loggedInUserId) {
      setCurrentUserId(loggedInUserId);
      setLocalStorage("generatedUserId", null);
    } else if (generatedUserId) {
      setCurrentUserId(generatedUserId);
    } else {
      const generatedUserId = uuid();
      setCurrentUserId(generatedUserId);
      setLocalStorage("generatedUserId", generatedUserId);
    }

    const gamePlayCount = getLocalStorage("gamePlayCount") as number;
    if (!gamePlayCount) {
      setLocalStorage("gamePlayCount", 0);
    }
  }, []);

  useEffect(() => {
    if (campaign?.id) {
      fetchCampaignAttempts({
        variables: {
          campaignId: campaign?.id
        }
      });
    }
  }, [campaign?.id]);

  useEffect(() => {
    const loggedInUserId = getLocalStorage("userId");
    if (campaignAttemptsData && loggedInUserId) {
      setLocalStorage("gamePlayCount", campaignAttemptsData.numAttemptsToSupportCampaign);
    }
  }, [campaignAttemptsData]);

  useEffect(() => {
    getStashLink({
      variables: {
        stashlinkId: stashlinkId
      }
    });
  }, [getStashLink]);

  return {
    eligibleBrandsData,
    eligibleBrandsLoading,
    isMatchSponsor,
    campaignAttemptsLoading,
    fetchBrands,
    stashlink,
    stashlinkLoading
  };
};

export default useCampaignProfile;
