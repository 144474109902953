import React from "react";
import MarqueeComponent from "../../../components/common/marquee/marqueeComponent";
import GameBackground from "../../../assets/images/game-star.png";
import "../../../assets/css/index.css";
import useGameBubblePop from "../hooks/useGameBubblePop";
import { emptyBubble } from "../../../common/constants";
import CompleteGame from "./completeGame";
import InstructionalModal from "./instructionModal";
import ImageContainer from "../../../components/common/image-conatiner/image-container";
import { generateCloudinaryImageUrl, getLocalStorage } from "../../../utils/common-function";

const BubblePopGame: React.FC = () => {
  const { bubbles, total, isGameComplete, isModalOpen, revealBubble, closeModal, setIsModalOpen } = useGameBubblePop();
  const brandsData = getLocalStorage("brandsData");

  return isGameComplete ? (
    <CompleteGame
      brandName={brandsData?.sampleEligibleBrands?.brand.name || ""}
      brandLogo={brandsData?.sampleEligibleBrands?.brand.imageUrl || ""}
    />
  ) : (
    <div className="flex flex-col items-center bg-soft-black text-white select-none">
      <div className="w-screen bg-black">
        <MarqueeComponent text=" Tap Tap Tap &nbsp; 👆 &nbsp; Tap Tap Tap " iterationsPerScreen={4} separatorIcon={"👇"} />
      </div>
      <div className="w-full h-c-70 xsh:h-c-62 flex flex-col items-center justify-center relative overflow-hidden">
        <div className="absolute h-full w-full">
          <div className="absolute top-4 -left-20 xsh:-top-16 z-0 bubble-game-background">
            <img src={GameBackground} alt={GameBackground} className="w-full object-contain" />
          </div>
        </div>
        <div className="relative p-4 z-10">
          <div className="grid grid-cols-7 gap-2 mb-6">
            {bubbles.map((bubble, index) =>
              emptyBubble.includes(index) ? (
                <div key={index} className="w-10 h-10 rounded-full"></div>
              ) : (
                <div
                  key={index}
                  onClick={() => revealBubble(index)}
                  onTouchStart={() => revealBubble(index)}
                  className={`w-10 h-10 rounded-full flex items-center justify-center cursor-pointer ${
                    bubble.revealed ? "bg-primary-l1/40" : "bg-black-op50"
                  }`}>
                  {bubble.revealed && <div className="coin flipping"></div>}
                </div>
              )
            )}
          </div>

          <div className="absolute inset-0 flex items-center justify-center pb-6 pointer-events-none">
            <div
              onClick={() => setIsModalOpen(true)}
              className="w-137 h-137 bg-grey/30 rounded-42px flex items-center justify-center p-1 pointer-events-auto">
              <ImageContainer
                src={generateCloudinaryImageUrl(brandsData?.sampleEligibleBrands?.brand.imageUrl || "", "243", "243")}
                className="rounded-42px border-4 w-122 h-122 border-black"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${total === 0 ? "bg-grey-bg" : "bg-modal-top-bg"} -mt-8 z-10 w-40 h-11 rounded-4xl flex flex-col items-center justify-center`}>
        <h1 className={total === 0 ? "text-grey" : "text-primary-l1"}>${total === 0 ? total : total.toFixed(2)}</h1>
      </div>
      <p className="font-secondary mt-5 text-base text-white leading-tight font-normal">Every cent you stash is donated by </p>
      <h1 className="font-primary capitalize text-center text-28 font-extrabold mt-2 leading-tight-29.54 text-neutral-50">{brandsData?.sampleEligibleBrands?.brand.name || ""}</h1>
      <InstructionalModal brandName={brandsData?.sampleEligibleBrands?.brand.name || ""} isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default BubblePopGame;
