import React from "react";
import { SocialMediaButtonsProps } from "./social-media-buttons.types";
import FaceBookIcon from "../../svgImages/faceBook";
import { getColor } from "../../../utils/common-function";
import InstaGramIcon from "../../svgImages/instaGram";
import TwitterIcon from "../../svgImages/twitter";
import YouTubeIcon from "../../svgImages/youTube";
import TikTokIcon from "../../svgImages/tiktok";
import LinkedInIcon from "../../svgImages/linkedIn";

const SocialMediaButtons: React.FC<SocialMediaButtonsProps> = ({
  facebookUsername,
  twitterUsername,
  instagramUsername,
  youtubeUsername,
  tiktokUsername,
  linkedinUrl,
  impactScoreLevel
}) => {
  const socialUsernameMap: { [key: string]: string } = {
    ...(!!facebookUsername?.length && { facebook: facebookUsername }),
    ...(!!twitterUsername?.length && { twitter: twitterUsername }),
    ...(!!instagramUsername?.length && { instagram: instagramUsername }),
    ...(!!youtubeUsername?.length && { youtube: youtubeUsername }),
    ...(!!tiktokUsername?.length && { tiktok: tiktokUsername }),
    ...(!!linkedinUrl?.length && { linkedin: linkedinUrl })
  };

  const socialNetworks = Object.keys(socialUsernameMap);
  const lightColorStyles = getColor(impactScoreLevel);

  const iconMap: { [key: string]: JSX.Element } = {
    facebook: <FaceBookIcon color={lightColorStyles.levelLightColor} className="w-[30px]" />,
    twitter: <TwitterIcon color={lightColorStyles.levelLightColor} className="w-[30px]" />,
    instagram: <InstaGramIcon color={lightColorStyles.levelLightColor} className="w-[30px]" />,
    youtube: <YouTubeIcon color={lightColorStyles.levelLightColor} className="w-[30px]" />,
    tiktok: <TikTokIcon color={lightColorStyles.levelLightColor} className="w-[30px]" />,
    linkedin: <LinkedInIcon color={lightColorStyles.levelLightColor} className="w-[30px]" />
  };

  return (
    <div className="flex flex-row gap-7">
      {socialNetworks.map((socialNetwork) => {
        const url =
          socialNetwork === "linkedin"
            ? socialUsernameMap[socialNetwork]
            : `https://${socialNetwork}.com/${socialUsernameMap[socialNetwork]}`;

        return (
          <a key={socialNetwork} href={url} target="_blank" rel="noopener noreferrer" className="hover:text-gray-900">
            {iconMap[socialNetwork]}
          </a>
        );
      })}
    </div>
  );
};

export default SocialMediaButtons;
