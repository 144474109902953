import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { routes } from "../../../routes/routes.constant";
import { timeout } from "../../../common/constants";
import CoinCollect5 from "../../../assets/sounds/coin-collect5.mp3";

export const useCompleteGame = () => {
  const [showCoin, setShowCoin] = useState(false);
  const [showDollarCoin, setShowDollarCoin] = useState(false);
  const moneySound = new Audio(CoinCollect5);

  const navigate = useHistory();

  useEffect(() => {
    const showCoinTimer = setTimeout(() => {
      setShowCoin(true);
    }, timeout.twoThousand);

    const showDollarCoinTimer = setTimeout(() => {
      setShowCoin(false);
      moneySound.play();
      setShowDollarCoin(true);
    }, timeout.fourThousand);

    const navigateTimer = setTimeout(() => {
      navigate.replace({ ...location, state: {} });
      navigate.replace(routes.campaignComplete, { fromValidFlow: true });
    }, timeout.sixThousand);

    return () => {
      clearTimeout(showCoinTimer);
      clearTimeout(showDollarCoinTimer);
      clearTimeout(navigateTimer);
    };
  }, [navigate]);

  return { showCoin, showDollarCoin };
};
