import React from "react";
import { RxCross2 } from "react-icons/rx";
import { BottomDrawerProps } from "./types";

const BottomDrawer: React.FC<BottomDrawerProps> = ({ isOpen, onClose, children }) => (
  <div className={`fixed inset-0 bg-black/50 bg-opacity-50 z-50 flex items-end backdrop-filter-blur ${isOpen ? "block" : "hidden"}`}>
    <div
      className="w-full bg-gradient-to-t from-modal-bottom-bg to-modal-top-bg rounded-t-2xl transform transition-transform duration-300 ease-in-out"
      style={{ transform: isOpen ? "translateY(0)" : "translateY(100%)" }}>
      <button onClick={onClose} className="absolute top-4 right-4 text-grey text-lg hover:text-gray-100 font-semibold z-10">
        <RxCross2 />
      </button>
      <div>{children}</div>
    </div>
  </div>
);

export default BottomDrawer;
