import React from "react";
import { FiX } from "react-icons/fi";
import { ImSpinner2 } from "react-icons/im";
import { useHistory } from "react-router-dom";
import Button from "../common/button";

const LoadingVideo: React.FC = () => {
  const history = useHistory();
  return (
    <div className="bg-soft-black min-h-[calc(100vh-140px)] flex flex-col justify-center items-center p-4">
      <div className=" relative w-full bg-grey-bg rounded-3xl h-368 p-6 border-dashed border-2 border-grey/30 flex flex-col justify-end items-center">
        <div className="absolute right-4 top-4 p-1 flex justify-center items-center bg-grey rounded-full">
          <button className="text-gray-800" onClick={() => history.goBack()}>
            <FiX size={20} className="font-bold" />
          </button>
        </div>
        <div className="absolute left-2/4 top-2/4 -translate-x-2/4 -translate-y-2/4 flex justify-center items-center mb-6">
          <ImSpinner2 className="animate-spin text-gray-300" size={32} />
        </div>
        <div className="w-full">
          <p className="font-secondary text-center text-placeholder-grey mb-2 text-xs">Uploading...</p>
          <div className="w-full bg-gray-700 rounded-full h-2.5">
            <div className="bg-grey h-2.5 rounded-full" style={{ width: "50%" }}></div>
          </div>
        </div>
      </div>
      <div className="bg-black p-4 pb-6 z-10 fixed w-full bottom-0 left-0">
        <Button title="Create stashlink + Share" isDisabled={true} />
      </div>
    </div>
  );
};

export default LoadingVideo;
