import { useEffect, useRef } from "react";
import CoinCollect1 from "../../../assets/sounds/coin-collect1.mp3";
import CoinCollect2 from "../../../assets/sounds/coin-collect2.mp3";
import CoinCollect3 from "../../../assets/sounds/coin-collect3.mp3";
import CoinCollect4 from "../../../assets/sounds/coin-collect4.mp3";
import BackGroundMusic from "../../../assets/sounds/bubble-pop-background.mp3";

const useGameSounds = () => {
  const gameBeginMusic = useRef<HTMLAudioElement | null>(null);
  const moneySounds = useRef<HTMLAudioElement[]>([]);
  const lastBubbleSound = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    gameBeginMusic.current = new Audio(BackGroundMusic);
    moneySounds.current = [new Audio(CoinCollect1), new Audio(CoinCollect2), new Audio(CoinCollect3)];
    lastBubbleSound.current = new Audio(CoinCollect4);

    gameBeginMusic.current.load();
    moneySounds.current.forEach((sound) => sound.load());
    lastBubbleSound.current?.load();
  }, []);

  const preloadSounds = () => {
    gameBeginMusic.current?.load();
    moneySounds.current.forEach((sound) => sound.load());
    lastBubbleSound.current?.load();
  };

  const startGameMusic = () => {
    if (gameBeginMusic.current) {
      gameBeginMusic.current.currentTime = 0;
      gameBeginMusic.current.play();
    }
  };

  const stopGameMusic = () => {
    gameBeginMusic.current?.pause();
  };

  const playMoneySound = () => {
    const randomSound = moneySounds.current[Math.floor(Math.random() * moneySounds.current.length)];
    const clonedSound = randomSound.cloneNode() as HTMLAudioElement;
    clonedSound.currentTime = 0;
    clonedSound.play();
  };

  const playLastBubbleSound = () => {
    if (lastBubbleSound.current) {
      const clonedSound = lastBubbleSound.current.cloneNode() as HTMLAudioElement;
      clonedSound.currentTime = 0;
      clonedSound.play();
    }
  };

  return {
    preloadSounds,
    startGameMusic,
    playMoneySound,
    playLastBubbleSound,
    stopGameMusic
  };
};

export default useGameSounds;
