import { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../../../routes/routes.constant";
import { useAuth } from "../../../../store/contextAPI/authContext";
import useUser from "../../../../services/hooks/useUser";
import { getLocalStorage } from "../../../../utils/common-function";

const useEnterDob = () => {
  const history = useHistory();
  const location = useLocation();
  const { user, setDOB } = useAuth();
  const userId = getLocalStorage("userId");
  const { updateUserProfile } = useUser(userId);
  const [month, setMonth] = useState<string>(user.dobMonth ?? "");
  const [error, setError] = useState<string | null>(null);
  const [date, setDate] = useState<string>(user.dobDay ?? "");
  const [year, setYear] = useState<string>(user.dobYear ?? "");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const lastValidDOB = useMemo((): string => {
    const currentDate = new Date();
    const dateBefore16Years = new Date(currentDate.getFullYear() - 16, currentDate.getMonth(), currentDate.getDate()).toDateString();
    return dateBefore16Years;
  }, []);

  const handleMonthChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const monthValue = event.target.value;
    if (monthValue.length <= 2) {
      setMonth(!Number.isNaN(parseInt(monthValue, 10)) ? monthValue : "");
    }
  }, []);

  const handleMonthBlur = useCallback(() => {
    if (month.length === 1) {
      setMonth(`0${month}`);
    }
  }, [month]);

  const handleDateChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const dayValue = event.target.value;
    if (dayValue.length <= 2) {
      setDate(!Number.isNaN(parseInt(dayValue, 10)) ? dayValue : "");
    }
  }, []);

  const handleDateBlur = useCallback(() => {
    if (date.length === 1) {
      setDate(`0${date}`);
    }
  }, [date]);

  const handleYearChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const yearValue = event.target.value;
    if (yearValue.length <= 4) {
      setYear(!Number.isNaN(parseInt(yearValue, 10)) ? yearValue : "");
    }
  }, []);

  const isAgeValid = useCallback((dob: string): boolean => {
    const birthDate = new Date(dob);
    const currentDate = new Date();
    let age: number = currentDate.getFullYear() - birthDate.getFullYear();
    const monthDifference: number = currentDate.getMonth() - birthDate.getMonth();
    const dayDifference: number = currentDate.getDate() - birthDate.getDate();

    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
      age--;
    }
    return age >= 16;
  }, []);

  const validateBirthdate = useCallback((): boolean => {
    const dob = `${year}-${month}-${date}`;
    if (
      month.length < 1 ||
      date.length < 1 ||
      year.length < 4 ||
      Number(parseInt(year, 10)) < 1900 ||
      !isAgeValid(dob) ||
      new Date(dob).toString() === "Invalid Date"
    ) {
      setErrorMessage("Please enter a valid date format");
      return false;
    }
    setErrorMessage("");
    return true;
  }, [date, isAgeValid, month, year]);

  const isContinueDisable = useMemo(
    (): boolean => month.length === 0 || year.length === 0 || date.length === 0 || year.length < 4,
    [date.length, month.length, year.length]
  );

  const onUpdateProfileSuccess = useCallback(() => {
    history.push({ pathname: routes.addPhoto, state: location.state });
  }, [history]);

  const onUpdateProfileError = useCallback(() => {
    setError("An error occurred while updating your profile. Please try again.");
  }, []);

  const onClickContinueHandler = useCallback(() => {
    if (validateBirthdate()) {
      setDOB(date, month, year);
      updateUserProfile({ birthdate: `${year}-${month}-${date}` }, onUpdateProfileSuccess, onUpdateProfileError);
    }
  }, [date, history, month, setDOB, validateBirthdate, year, location]);

  return {
    date,
    month,
    year,
    error,
    errorMessage,
    handleMonthBlur,
    handleDateBlur,
    lastValidDOB,
    handleDateChange,
    handleMonthChange,
    handleYearChange,
    onClickContinueHandler,
    isContinueDisable
  };
};

export default useEnterDob;
