import React, { useEffect } from "react";
import Button from "../../components/common/button";
import Dollar from "./../../assets/images/Dollar.svg";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../routes/routes.constant";
import { oneDollar } from "../../common/constants";
import { generateCloudinaryImageUrl, getLocalStorage } from "../../utils/common-function";
import { SampleEligibleBrandsData } from "../../types";
import confetti from "./../../assets/images/Confetti.gif";
import useAuthStatus from "./hooks/useAuthStatus";
import { useImpactScoreModal } from "../../services/hooks/useImpactScoreModal";
import ImpactScoreModal from "../../components/impactScoreModal/impactScoreModal";

const GameComplete: React.FC = () => {
  const navigation = useHistory();
  const coinDropId = getLocalStorage("coinDropId");
  const brandData = getLocalStorage("brandsData") as SampleEligibleBrandsData;
  const { currentUser } = useAuthStatus();
  const { isImpactScoreModalOpen, setIsImpactScoreModalOpen, impactScoreLevel } = useImpactScoreModal(currentUser?.user);
  const location = useLocation();

  useEffect(() => {
    const state = location.state as { fromValidFlow: boolean };
    if (!state?.fromValidFlow) {
      const stashLink = getLocalStorage("stashLink");
      navigation.push(stashLink ? stashLink : "/");
    }
  }, [location.state]);

  return (
    <div
      className="relative flex flex-col gap-6 w-screen justify-center items-center h-[calc(100vh-133px)] bg-soft-black text-white"
      style={{ backgroundImage: `url(${confetti})` }}>
      <div className="relative z-10 gap-6 flex flex-col items-center">
        <h2 className="text-2xl text-base-white font-primary">So easy, right?!</h2>
        <div className="gap-4 overflow-hidden border-solid border-2 border-modal-border text-white p-1 py-3 rounded-3xl flex flex-col items-center w-80 mx-auto bg-gradient-to-t from-modal-bottom-bg to-modal-top-bg">
          <div className="flex flex-col items-center justify-center">
            <p className="font-secondary font-normal text-base-white text-xl">You stashed</p>
            <h1 className="text-primary-l1 text-4xl font-primary my-1">${oneDollar}</h1>
            <div className="font-secondary text-xl text-base-white">
              for <span className="font-semibold">{brandData.sampleEligibleBrands?.campaign.charity?.name}</span>
            </div>
          </div>
          <div className="w-full h-full rounded-lg overflow-hidden relative">
            <div className="p-2 absolute -left-2 -top-2 w-[72px] h-[72px] rounded-full bg-gradient-to-t from-[#36400f] to-[#313a0e] flex justify-center items-center">
              <img
                src={generateCloudinaryImageUrl(brandData.sampleEligibleBrands?.campaign.charity?.imageUrl || "", "104", "104")}
                alt="charity logo"
                className="h-full w-full object-cover rounded-full"
              />
            </div>

            <img
              src={generateCloudinaryImageUrl(brandData.sampleEligibleBrands?.campaign?.imageUrl || "", "596", "424")}
              alt="When We All Vote"
              className="w-full h-[212px] object-cover rounded-xl"
            />
          </div>
          <div className="flex flex-row gap-1">
            <img src={Dollar} alt={Dollar} />

            <p className="font-secondary text-grey-op70">#{coinDropId || 0}</p>
          </div>
        </div>
      </div>
      <div className="fixed bottom-0 w-full flex p-3 z-10 items-center justify-center bg-pure-black">
        <Button
          title="Continue"
          isDisabled={false}
          onClick={() => {
            navigation.replace({ ...location, state: {} });
            navigation.replace(routes.campaignThankYou, { fromValidFlow: true });
          }}
        />
      </div>
      {isImpactScoreModalOpen && (
        <ImpactScoreModal
          user={currentUser?.user}
          impactScoreLevel={impactScoreLevel}
          setIsImpactScoreModalOpen={setIsImpactScoreModalOpen}
        />
      )}
    </div>
  );
};

export default GameComplete;
