import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import ErrorImage from "../../assets/images/404Image.svg";
import { useErrorBoundary } from "react-error-boundary";

const ErrorScreen: React.FC = () => {
  const { resetBoundary } = useErrorBoundary();
  return (
    <div className="relative bg-soft-black w-screen h-screen flex flex-col justify-between items-center text-white">
      <div className="relative w-full">
        <IoIosArrowBack className="absolute top-4 left-4 text-gray-400 h-6 w-6 cursor-pointer" onClick={resetBoundary} />
      </div>
      <div className="text-center">
        <h1>404</h1>
        <p>Oops, something went wrong</p>
      </div>
      <img src={ErrorImage} alt="error-screen" />
    </div>
  );
};

export default ErrorScreen;
