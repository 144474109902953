import React, { createContext, useCallback, useContext, useState } from "react";
import { AuthContextType, AuthProviderProps, ISignupCredentials, OAuthProvider } from "./types";
import { supabaseClient } from "../../services/supabase/supabaseClient";
import { deleteCookie, setCookie, setLocalStorage } from "../../utils/common-function";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../routes/routes.constant";
import { envVariables } from "../../common/constants";

const AuthContext = createContext<AuthContextType | undefined>(undefined);
const emptyFile = new File(["dummy content"], "example.jpg", {
  type: "image/jpeg",
  lastModified: Date.now()
});

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const history = useHistory();
  const location = useLocation();
  const [user, setUser] = useState({
    email: "",
    isSSOError: false,
    password: "",
    firstName: "",
    lastName: "",
    dobDay: "",
    dobMonth: "",
    dobYear: "",
    photo: emptyFile,
    token: "",
    bio: "",
    impactScore: 2100,
    instagramId: "",
    tiktokId: "",
    youtube: "",
    isProfileComplete: false
  });

  const setEmailContext = (email: string) => {
    setUser({ ...user, email: email });
  };

  const setProfileComplete = () => {
    setUser({ ...user, isProfileComplete: true });
  };

  const setPasswordContext = (password: string) => {
    setUser({ ...user, password: password });
  };

  const setSSOErrorContext = (isError: boolean) => {
    setUser({ ...user, isSSOError: isError });
  };

  const setNameContext = (firstName: string, lastName: string) => {
    setUser({ ...user, firstName: firstName, lastName: lastName });
  };

  const setDOB = (day: string, month: string, year: string) => {
    setUser({ ...user, dobDay: day, dobMonth: month, dobYear: year });
  };

  const setUserBioContext = (bio: string) => {
    setUser({ ...user, bio: bio });
  };

  const setProfilePicture = (picture: File) => {
    setUser({ ...user, photo: picture });
  };

  const setSocialHandlers = (instagramId: string, tiktokId: string, youtube: string) => {
    setUser({ ...user, instagramId: instagramId, tiktokId: tiktokId, youtube: youtube });
  };

  const setToken = (token: string) => {
    setUser({ ...user, token: token });
  };

  const logout = () => {
    setUser({
      email: "",
      password: "",
      isSSOError: false,
      firstName: "",
      lastName: "",
      dobDay: "",
      dobMonth: "",
      dobYear: "",
      photo: emptyFile,
      token: "",
      bio: "",
      instagramId: "",
      tiktokId: "",
      youtube: "",
      impactScore: 0,
      isProfileComplete: false
    });
  };

  const handleUserLogout = async () => {
    const { error } = await supabaseClient.auth.signOut();
    if (error) {
      console.error("Error logging out:", error.message);
    } else {
      deleteCookie("token");
      setToken("");
      localStorage.removeItem("userId");
      localStorage.removeItem("email");
      localStorage.removeItem("impactScoreLevel");
      localStorage.removeItem("gamePlayCount");
      history.push("/");
    }
  };

  const handleSignUp = useCallback(
    async (credentials: ISignupCredentials) => {
      const { data: signUpInfo, error } = await supabaseClient.auth.signUp(credentials);
      if (error) {
        console.log("Something went wrong !");
      } else {
        const token = signUpInfo.session?.access_token ?? "";
        setLocalStorage("supabaseUserId", signUpInfo.session?.user.id);
        setCookie("token", token);
        setToken(token);
        history.push({ pathname: routes.userName, state: location.state });
      }
    },
    [history, user, location]
  );

  const handleLogin = useCallback(
    async (credentials: ISignupCredentials) => {
      const { data: loginInfo, error } = await supabaseClient.auth.signInWithPassword(credentials);

      if (error) {
        return;
      } else {
        const token: string = loginInfo.session.access_token;
        setCookie("token", token);
        setToken(token);
        history.push({ pathname: routes.callback, state: location.state });
      }
    },
    [user, history, location]
  );

  const handleResetPassword = async (email: string) => {
    const { error } = await supabaseClient.auth.resetPasswordForEmail(email, {
      redirectTo: `${envVariables.domainUrl + routes.createPassword}`
    });

    if (error) {
      console.log("Something went wrong !");
    } else {
      history.push(routes.forgotPasswordConfirmation);
    }
  };

  const handleUpdatePassword = async (password: string) => {
    const { error } = await supabaseClient.auth.updateUser({
      password
    });

    if (error) {
      console.log("Something went wrong !");
    } else {
      history.push(routes.onboarding);
    }
  };

  const handleSocialLogins = async (provider: OAuthProvider) => {
    try {
      const { data, error } = await supabaseClient.auth.signInWithOAuth({
        provider,
        options: {
          redirectTo: `${envVariables.domainUrl + routes.callback}`
        }
      });

      if (error) {
        console.log(`Error logging in with ${provider}:`, error.message);
      } else {
        console.log(`Logged in with ${provider}:`, data);
      }
    } catch (error) {
      console.error(`Unexpected error logging in with ${provider}:`, error);
    }
  };

  const deleteUser = async (userId: string) => {
    const { error } = await supabaseClient.auth.admin.deleteUser(userId);

    if (error) {
      console.error("Error deleting user:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setEmailContext,
        setPasswordContext,
        setNameContext,
        setSSOErrorContext,
        logout,
        setDOB,
        setToken,
        setUserBioContext,
        setSocialHandlers,
        handleLogin,
        deleteUser,
        handleSignUp,
        setProfilePicture,
        handleResetPassword,
        handleUpdatePassword,
        handleSocialLogins,
        handleUserLogout,
        setProfileComplete
      }}>
      {children}
    </AuthContext.Provider>
  );
};
