import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../../../routes/routes.constant";
import { useAuth } from "../../../../store/contextAPI/authContext";
import { ILocationState, IValue } from "./types";
import { SEARCH_USER_BY_EMAIL } from "../../../../queries";
import { useQuery } from "@apollo/client";
import { SocialLoginProvider } from "../constant";
import { setLocalStorage } from "../../../../utils/common-function";

const useEnterEmail = () => {
  const history = useHistory();
  const location = useLocation<ILocationState>();
  const { user, setEmailContext, handleSocialLogins, setSSOErrorContext, logout } = useAuth();
  const [email, setEmail] = useState<IValue>({ value: user.email, error: "" });
  const [followerName, setFollowerName] = useState<string>("");
  const [followerProfileImage, setFollowerProfileImage] = useState<string>("");
  const [showFollowTag, setShowFollowTag] = useState<boolean>(false);

  useEffect(() => {
    logout();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getFollowTileContent();
  }, []);

  const getFollowTileContent = useCallback(() => {
    const { userName, followerProfileImage } = location.state || {};
    if (userName && followerProfileImage) {
      setShowFollowTag(true);
      setFollowerName(userName);
      setFollowerProfileImage(followerProfileImage);
    }
  }, [location, setFollowerName, setFollowerProfileImage]);

  const { refetch: searchUserByEmail } = useQuery(SEARCH_USER_BY_EMAIL, {
    variables: { email: email.value },
    skip: true
  });

  const isValidEmail = useCallback((input: string): boolean => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-z0-9-]+\.)+[a-z]{2,}))$/;
    return re.test(String(input).toLowerCase());
  }, []);

  const onClickGoogleHandler = useCallback(() => {
    setSSOErrorContext(false);
    handleSocialLogins(SocialLoginProvider.Google);
  }, [setSSOErrorContext, handleSocialLogins]);

  const onClickMetaHandler = useCallback(() => {
    setSSOErrorContext(false);
    handleSocialLogins(SocialLoginProvider.Facebook);
  }, [setSSOErrorContext, handleSocialLogins]);

  const onClickAppleHandler = useCallback(() => {
    setSSOErrorContext(false);
    handleSocialLogins(SocialLoginProvider.Apple);
  }, [setSSOErrorContext, handleSocialLogins]);

  const onChangeEmail = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const processedValue = value.replace(/\s+/g, "").toLowerCase();
      const emailError = processedValue.length === 0 ? "" : isValidEmail(processedValue) ? "" : "Please enter a valid email";
      setEmail({ value: processedValue, error: emailError });
    },
    [isValidEmail]
  );

  const isContinueDisable = useMemo((): boolean => !isValidEmail(email.value), [email.value, isValidEmail]);

  const onClickContinueHandler = useCallback(async () => {
    setEmailContext(email.value);
    setLocalStorage("email", email.value);
    const result = await searchUserByEmail({ variables: { email: email.value } });
    if (result.data) {
      if (result.data.searchByEmail) {
        history.push({ pathname: routes.login, state: location.state });
      } else {
        history.push({ pathname: routes.signup, state: location.state });
      }
    }
  }, [email.value, setEmailContext, searchUserByEmail]);

  return {
    user,
    email,
    onChangeEmail,
    isContinueDisable,
    onClickContinueHandler,
    onClickGoogleHandler,
    onClickMetaHandler,
    onClickAppleHandler,
    showFollowTag,
    followerName,
    followerProfileImage
  };
};

export default useEnterEmail;
