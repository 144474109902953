import { IDetailsTableProps, IDetailItem } from "./types";

const DetailsTable = (props: IDetailsTableProps) => {
  const { items } = props;
  return (
    <div className="bg-grey/5 text-grey-op40 p-4">
      <h2 className="text-grey text-22px font-primary">Details</h2>
      <table className="w-full mt-1.5 mb-1">
        <tbody>
          {items.map((item: IDetailItem, index: number) => (
            <tr key={index} className="border-t border-b border-gray-700">
              <td className="font-inter text-sm font-normal text-grey-op70 leading-[18.2px] text-left">{item.label}</td>
              <td className="font-inter text-sm font-medium text-grey-op70 text-base leading-[16.8px] text-right py-2">{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DetailsTable;
