import OnboardingWrapper from "../../../containers/onboardingWrapper";
import { withAuthGuard } from "../../../routes/helpers";
import { heading } from "./constant";
import EnterEmail from "./userEmail";

const Onboarding = () => (
  <OnboardingWrapper heading={heading.loginSignUpPage} backButtonPath={""}>
    <EnterEmail />
  </OnboardingWrapper>
);

export default withAuthGuard(Onboarding);
