import React from "react";
import SocialMediaButtons from "../common/social-media-buttons/social-media-buttons";
import { UserProfileProps } from "./userProfile.types";
import { generateProfileImageUrl, getStyles } from "../../utils/common-function";
import FlashIcon from "../svgImages/flashIcon";

const UserProfile: React.FC<UserProfileProps> = (props: UserProfileProps) => {
  const styles = getStyles(props.impactScoreLevel);

  return (
    <div className="relative pt-32">
      <div className="flex justify-center items-center">
        <div className="absolute -top-12 w-[164px] h-[168px] rounded-full" style={{ background: styles.levelColor }}>
          <div
            className="absolute bottom-[2px] left-2/4 -translate-x-2/4 w-[148px] h-[148px] rounded-full mx-auto"
            style={{
              backgroundImage: `url(${generateProfileImageUrl(props.user?.profilePhoto || "", "296", "296")})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat"
            }}></div>
          <div className="absolute -bottom-3 left-2/4 -translate-x-2/4 w-fit flex items-center text-base-white text-sm bg-gradient-to-t from-modal-bottom-bg to-modal-top-bg px-3 py-0 ps-10 rounded-2xl border-b-4 border-r-4 border-black/60">
            <span className="absolute left-0 top-0.5 text-3xl mr-1 text-base-white">
              <FlashIcon color={styles.levelColor} className="h-8 relative left-2 -top-1.5" />
            </span>
            <span className="min-h-[20px] text-xs font-semibold leading-[14.40px] relative top-1">{props.user?.impactScore}</span>
          </div>
        </div>
      </div>
      <h2 className="text-center text-2xl font-bold mb-2 mt-2 px-6" style={{ color: styles.levelColor }}>
        {props.user?.fullName}
      </h2>
      <p className="text-center text-sm mt-2 text-base-white/90 px-6">{props.user?.description}</p>
      <div className="flex justify-center space-x-4 mb-4 mt-4">
        <SocialMediaButtons
          instagramUsername={props.user?.instagramUsername}
          youtubeUsername={props.user?.youtubeUsername}
          tiktokUsername={props.user?.tiktokUsername}
          impactScoreLevel={props.impactScoreLevel}
        />
      </div>
    </div>
  );
};

export default UserProfile;
