import images from "../../../assets/images/images";
import { timeout } from "../../../common/constants";
import { preloadImages } from "../../../utils/common-function";
import { addCloudinaryTransformation } from "../../../utils/format";
import scroll from "../../../utils/scroll";
import { SponsorModalProps } from "./sponsor.types";
import React, { useEffect, useState } from "react";

const SponsorModal: React.FC<SponsorModalProps> = ({ sponsorName, sponsorImageUrl }) => {
  const { brandLogoAnimation } = images;
  const brandAnimation = addCloudinaryTransformation(brandLogoAnimation, "c_scale,w_100,dpr_2");
  const brandLogo = addCloudinaryTransformation(sponsorImageUrl, "c_scale,w_100,dpr_2");
  const [isMatched, setIsMatched] = useState(false);

  useEffect(() => {
    preloadImages([brandAnimation, brandLogo]);
    scroll.lock();
    const timer = setTimeout(() => {
      setIsMatched(true);
    }, timeout.twoThousandSixHundred);

    return () => {
      clearTimeout(timer);
      scroll.enable();
    };
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-grey-op40 bg-opacity-80 z-50 backdrop-filter-blur">
      {!isMatched ? (
        <div className="h-72 gap-8 sponsor-modal border-solid border-2 text-white p-11 rounded-3xl flex flex-col items-center w-80 mx-auto bg-soft-black border-pure-black justify-center">
          <h2 className="text-center tracking-wide">
            Matching you <br /> with a sponsor...
          </h2>
          <div className="flex items-center justify-center rounded-full w-[103px] h-[103px] overflow-hidden border-grey border-2">
            <img src={brandAnimation} alt={`${sponsorName} logo`} className="w-full h-full object-cover" />
          </div>
        </div>
      ) : (
        <div className="h-72 gap-5 sponsor-modal border-solid border-2 border-modal-border text-white p-9 rounded-3xl flex flex-col items-center w-80 mx-auto bg-soft-black border-pure-black">
          <div className="flex items-center justify-center rounded-full w-[144px] h-[144px] overflow-hidden border-base-white border-4">
            <img
              src={brandLogo}
              alt={`${sponsorName} logo`}
              className="min-w-full min-h-full max-w-full max-h-full object-cover rounded-full border-2 border-soft-black"
            />
          </div>
          <div className="text-center">
            <h2 className="text-center tracking-wider">{sponsorName}</h2>
            <span className="font-normal font-Inter">will pay for this donation </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default SponsorModal;
