import { useQuery, ApolloError } from "@apollo/client";
import { FETCH_USERS_CAMPAIGNS } from "../../queries";
import { Campaign } from "../../types";

interface userCampaignsResponse {
  campaignsSearch: Campaign[];
}

interface UseUserCampaignsHook {
  userCampaignsLoading: boolean;
  userCampaignsError?: ApolloError;
  activeUserCampaigns: Campaign[];
  inActiveUserCampaigns: Campaign[];
}

const useUserCampaigns = (userId: string): UseUserCampaignsHook => {
  const {
    loading: userCampaignsLoading,
    error: userCampaignsError,
    data
  } = useQuery<userCampaignsResponse>(FETCH_USERS_CAMPAIGNS, {
    variables: {
      userId
    },
    fetchPolicy: "cache-first"
  });

  const userCampaignsData = data?.campaignsSearch || [];

  const currentTime = new Date();

  const inActiveUserCampaigns = userCampaignsData?.filter(
    (campaign: Campaign) =>
      campaign.currentAmount >= campaign.goalAmount ||
      (campaign.endTime && new Date(campaign.endTime) > currentTime) ||
      !campaign.canSupport
  );

  const activeUserCampaigns = userCampaignsData.filter((campaign: Campaign) => !inActiveUserCampaigns?.includes(campaign));

  return { userCampaignsLoading, userCampaignsError, activeUserCampaigns, inActiveUserCampaigns };
};

export default useUserCampaigns;
