import React from "react";
import { ImpactScoreModalProps } from "./impactScoreModal.types";
import { confettiDuration, IMPACT_LEVEL_COLORS, maxImpactScoreLevel } from "../../utils/constants";
import ConfettiExplosion from "react-confetti-explosion";
import CoinIcon from "../svgImages/coinIcon";
import LockIcon from "../svgImages/lockIcon";
import useConfetti from "../../services/hooks/useConfetti";
import { getStyles } from "../../utils/common-function";

const ImpactScoreModal: React.FC<ImpactScoreModalProps> = (props: ImpactScoreModalProps) => {
  const [isExploding] = useConfetti(confettiDuration);

  const getImpactScoreLevelColor = (level: number) => {
    const colorKey = level.toString();
    return IMPACT_LEVEL_COLORS[colorKey];
  };

  const styles = getStyles(props.impactScoreLevel);

  return (
    <div className="fixed inset-0 bg-modal-bg/80 flex items-center justify-center z-50 backdrop-blur-5">
      <div
        className="w-310 relative w-80 pt-16 px-4 pb-6 rounded-2xl"
        style={{
          background: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), linear-gradient(to top, ${styles.levelColor} 0%, ${styles.levelColor} 100%)`,
          border: styles.levelColor
        }}>
        {isExploding && (
          <div className="absolute top-0 inset-x-0 flex items-center justify-center z-100">
            <ConfettiExplosion
              force={0.8}
              duration={3500}
              particleCount={200}
              width={1800}
              zIndex={55}
              colors={["#FF4C4C", "#F8D800", "#FF7D4D", "#F85F73", "#29A19C", "#B2D9D5", "#D81159", "#3A86FF"]}
            />
          </div>
        )}
        <div className="flex justify-center items-center">
          <div
            className="absolute -top-12 w-32 h-132 rounded-full"
            style={{
              backgroundColor: props.impactScoreLevel ? IMPACT_LEVEL_COLORS[props.impactScoreLevel] : ""
            }}>
            <img
              src={props.user?.profilePhoto}
              alt={props.user?.fullName}
              className="relative top-3 w-116 w-116 rounded-full mx-auto"
            />
          </div>
          <div className="relative flex items-center justify-center w-full relative">
            {/* The following images will be displayed only if the impact score level is greater than one, indicating that the level has been unlocked. */}
            <div className="flex absolute -left-2 top-0 min-w-20 justify-end">
              {[...Array(Math.min(Number(props.impactScoreLevel) - 1, 2))].map((_, index) => (
                <div key={index} className="mr-2 w-8 h-8 bg-grey-op70/300 rounded-full">
                  <CoinIcon
                    color={getImpactScoreLevelColor(Number(props.impactScoreLevel) - (2 - index))}
                    className="flex items-center justify-center object-contain"
                  />
                </div>
              ))}
            </div>
            <span
              className="absolute top-1 left-2/4 -translate-x-2/4 min-w-32 text-center font-primary text-sm font-bold leading-normal tracking-0.2 text-black rounded-full px-2 py-1"
              style={{
                backgroundColor: props.impactScoreLevel ? IMPACT_LEVEL_COLORS[props.impactScoreLevel] : ""
              }}>
              {props.user?.impactScore} impact
            </span>
            <div className="flex absolute top-0 -right-2 min-w-20 justify-start">
              <div className="flex">
                {[...Array(Math.min(2, maxImpactScoreLevel - Number(props.impactScoreLevel)))].map((_, index) => (
                  <div
                    key={index}
                    className="ml-2 -top-12 w-8 h-8 rounded-full flex justify-center"
                    style={{
                      backgroundColor: props.impactScoreLevel ? getImpactScoreLevelColor(Number(props.impactScoreLevel) + (index + 1)) : ""
                    }}>
                    <div
                      className="bg-gradient-to-t relative top-1.5 w-6 h-6 rounded-full flex justify-center items-center"
                      style={{
                        background: `linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), linear-gradient(to top, ${styles.levelColor} 0%, ${styles.levelColor} 100%)`,
                        border: styles.levelColor
                      }}>
                      <LockIcon
                        color={getImpactScoreLevelColor(Number(props.impactScoreLevel) + (index + 1))}
                        className="w-3 h-3 rounded-full"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 text-white">
          <h2 className="font-primary text-xl font-wght-750 text-center leading-6">level {props.impactScoreLevel}</h2>
          <p className="my-2 font-secondary font-inter text-base font-normal leading-tight-19.52 text-center text-white/80">
            {`Congrats! You're earning impact. Increase your score and unlock new levels by playing and sharing.`}
          </p>
        </div>
        <div
          className="w-full gap-[8.01px] rounded-2xl mt-8"
          style={{
            backgroundColor: props.impactScoreLevel ? IMPACT_LEVEL_COLORS[props.impactScoreLevel] : ""
          }}>
          <div className="bg-black/40 rounded-lg pb-1.5">
            <button
              className="w-full px-4 py-3 font-primary text-base font-wght-750 leading-tight-19.2 text-center tracking-wider rounded-lg text-black border-2 border-black"
              style={{
                backgroundColor: props.impactScoreLevel ? IMPACT_LEVEL_COLORS[props.impactScoreLevel] : ""
              }}
              onClick={() => props.setIsImpactScoreModalOpen(false)}>
              Ok, got it
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImpactScoreModal;
