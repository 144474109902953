import React from "react";
import DollarCoin from "../../../assets/images/dollar-coin.png";
import "../../../assets/css/index.css";
import { BrandProps } from "../types";
import { useCompleteGame } from "../hooks/useCompleteGame";
import confetti from "./../../../assets/images/Confetti.gif";

const Complete: React.FC<BrandProps> = ({ brandLogo, brandName }) => {
  const { showCoin, showDollarCoin } = useCompleteGame();

  return (
    <div
      className="h-c-100-57 text-white w-screen flex flex-col justify-center items-center bg-soft-black gap-6 bg-repeat"
      style={{ backgroundImage: `url(${confetti})` }}>
      {!showCoin && !showDollarCoin && (
        <div className="w-[144px] h-[144px] bg-primary-l1 p-2 rounded-full overflow-hidden">
          <img src={brandLogo} alt="Brand Logo" className="w-full h-full rounded-full border-4 border-soft-black" />
        </div>
      )}
      {showCoin && <div className="big-coin coin flipping" />}

      {showDollarCoin && <img src={DollarCoin} alt="Dollar Coin" className=" h-[136px]" />}
      <div className="text-center">
        <p className="font-secondary mt-4">Every cent you stash is donated by </p>
        <h1>{brandName}</h1>
      </div>
    </div>
  );
};

export default Complete;
