import ErrorImage from "../../assets/images/404Image.svg";

const NotFound = () => (
  <div className="fixed bg-soft-black w-screen h-c-100-57 flex flex-col justify-center items-center text-white">
    <h1>404</h1>
    <p>Oops, Page not found !!</p>
    <img className="fixed bottom-0" src={ErrorImage} alt="error-screen" />
  </div>
);

export default NotFound;
