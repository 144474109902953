import "cropperjs/dist/cropper.css";
import React, { useCallback, useRef, useState } from "react";
import { ReactCropperElement } from "react-cropper";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../../../routes/routes.constant";
import useUser from "../../../../services/hooks/useUser";
import { useAuth } from "../../../../store/contextAPI/authContext";
import { getLocalStorage } from "../../../../utils/common-function";

const useAddPhoto = () => {
  const history = useHistory();
  const location = useLocation();
  const { setProfilePicture } = useAuth();
  const userId = getLocalStorage("userId");
  const { updateUserProfile } = useUser(userId);
  const [profileImageUri, setProfileImageUri] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const [image, setImage] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const cropperRef = useRef<ReactCropperElement>(null);

  const onAddPhotoButtonHandler = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, []);

  const cancelCrop = () => setProfileImageUri("");

  const onUpdateProfileSuccess = useCallback(() => {
    history.push({ pathname: routes.userBio, state: location.state });
  }, [history, location.state]);

  const onUpdateProfileError = useCallback(() => {
    setError("An error occurred while updating your profile. Please try again.");
    setProfileImageUri("");
  }, []);

  const handlePhotoChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];

    if (file) {
      if (!validImageTypes.includes(file.type)) {
        setError("Please select a valid image file (JPEG, PNG, GIF, or WebP).");
      } else {
        setError("");
        setImage(file);
        setProfileImageUri(URL.createObjectURL(file));
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  }, []);

  const handleCrop = useCallback(async () => {
    const cropperInstance = cropperRef.current?.cropper;
    if (cropperInstance) {
      const croppedCanvas = cropperInstance.getCroppedCanvas();
      croppedCanvas.toBlob(async (blob: Blob | null) => {
        if (blob) {
          const editedFile = new File([blob], image?.name || "profile-image", {
            type: image?.type || "image/jpeg"
          });
          setProfilePicture(editedFile);
          updateUserProfile({ profileImageUrl: editedFile }, onUpdateProfileSuccess, onUpdateProfileError);
        }
      });
    }
  }, [image, history, onUpdateProfileSuccess, onUpdateProfileError, setProfilePicture, updateUserProfile]);

  return {
    profileImageUri,
    error,
    onAddPhotoButtonHandler,
    handlePhotoChange,
    handleCrop,
    fileInputRef,
    cropperRef,
    cancelCrop
  };
};

export default useAddPhoto;
