import React from "react";
import Button from "../../../components/common/button";
import Coin from "../../../assets/images/StashCoin.png";
import { InstructionalModalProps } from "../types";

const InstructionalModal: React.FC<InstructionalModalProps> = ({ isOpen, onClose, brandName }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-60 flex items-center justify-center z-50 backdrop-filter-blur">
      <div className="bg-primary-l1 text-base-white rounded-2xl w-80 relative">
        <div className="bg-black/75 p-6 rounded-2xl">
          <h2 className="text-xl text-[#fafafa] font-bold text-center mb-4">How to play</h2>
          <p className="text-center text-[#fafafa] mb-4">Tap the bubbles to reveal coins!</p>
          <div className="flex justify-center mb-4">
            <div className="w-10 h-10 bg-grey-op70/30 rounded-full mx-2 flex items-center justify-center"></div>

            <div className="w-10 h-10 bg-grey-op70/300 rounded-full mx-2">
              <img className="w-10 h-10 flex items-center justify-center" src={Coin} />
            </div>
            <div className="w-10 h-10 bg-grey-op70/30 rounded-full mx-2"></div>
            <div className="w-10 h-10 bg-grey-op70/30 rounded-full mx-2"></div>
            <div className="w-10 h-10 bg-grey-op70/30 rounded-full mx-2 flex items-center justify-center">
              <img className="w-10 h-10 flex items-center justify-center" src={Coin} />
            </div>
          </div>
          <p className="text-center text-[#fafafa] mb-6">
            Every coin you tap is <span className="text-primary-l1 font-bold">real money</span> donated by{" "}
            <span className="font-bold">{brandName}</span>
          </p>
          <Button title="Let’s stash!" isDisabled={false} onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export default InstructionalModal;
