import { ImpactLevelColors, ImpactLevels, TimeUnit } from "../types";

export const suggestEmail = "suggest@stashrun.com";

export const contactEmail = "contact@stashrun.com";

export const usersCountOnHomePage = 5;

export const marqueeTextIteration = 2;

export const h2hTimerAlertInHours = 6;

export const staticStashlinkId = "9805a809-634a-4874-854f-ce362441b630";

export const timeUnits: { unit: TimeUnit; label: string }[] = [
  { unit: "days", label: "d" },
  { unit: "hours", label: "h" },
  { unit: "minutes", label: "m" },
  { unit: "seconds", label: "s" }
];

export const fileSizeLimitInMB = 300;

export const confettiDuration = 3000;

export const maxImpactScoreLevel = 8;

export const defaultCoLinkUserLevel = "1";

export const IMPACT_LEVELS: ImpactLevels = Object.freeze({
  1: 500,
  2: 1000,
  3: 5000,
  4: 10000,
  5: 50000,
  6: 100000,
  7: 1000000,
  8: 10000000
} as const);

export const IMPACT_LEVEL_COLORS: ImpactLevelColors = Object.freeze({
  1: "#D7FF3E",
  2: "#FF9F0A",
  3: "#FF453A",
  4: "#FE00FE",
  5: "#C780FF",
  6: "#57ADFF",
  7: "#00F0FF",
  8: "#5FFA5C"
} as const);

export const IMPACT_LEVEL_LIGHT_COLORS: ImpactLevelColors = Object.freeze({
  1: "#ECFFA3",
  2: "#FFC770",
  3: "#FFA49E",
  4: "#FF99FF",
  5: "#E4C2FF",
  6: "#99CDFF",
  7: "#66F6FF",
  8: "#C0FDBF"
} as const);