import { useState, useEffect } from "react";
import { emptyBubble, gameDefaultAmounts, gameType, oneDollar, timeout } from "../../../common/constants";
import { Bubble } from "../types";
import { useMutation } from "@apollo/client";
import { SampleEligibleBrandsData, StartCoinDropData } from "../../../types";
import { COLLECT_COINS, START_COIN_DROP } from "../../../queries";
import { getLocalStorage, setLocalStorage } from "../../../utils/common-function";
import useGameSounds from "./useGameSounds";
import { useHistory, useLocation } from "react-router";
import {GestureHandler} from "../../../utils/gestureHandler";
import scroll from "../../../utils/scroll";

const useGameBubblePop = () => {
  const [bubbles, setBubbles] = useState<Bubble[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [isGameComplete, setIsGameComplete] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [instructionModal, setInstructionModal] = useState<boolean>(false);
  const brandsData = getLocalStorage("brandsData") as SampleEligibleBrandsData;
  const { playMoneySound, playLastBubbleSound, stopGameMusic, preloadSounds, startGameMusic } = useGameSounds();
  const location = useLocation();
  const navigation = useHistory();

  useEffect(() => {
    generateBubbles();
    preloadSounds();
  }, []);

  useEffect(() => {
    const unlisten = navigation.listen((location_, action) => {
      if (action === "POP") {
        stopGameMusic();
      }
    });
    return () => {
      unlisten();
    };
  }, [navigation]);

  useEffect(() => {
    const state = location.state as { fromValidFlow: boolean };
    if (!state?.fromValidFlow) {
      const stashLink = getLocalStorage("stashLink");
      navigation.push(stashLink ? stashLink : "/");
    }
  }, [location.state]);

  useEffect(() => {
    scroll.lock();
    return () => {
      scroll.enable();
    };
  }, []);

  useEffect(() => {
    //Prevent swipe right event
    const targetElement  = document.querySelector('div');
    const gestureHandler  = new GestureHandler(targetElement);
    
    gestureHandler.attachPreventSwipeRightListeners();
    if(isGameComplete)
      gestureHandler.detachPreventSwipeRightListeners();

    return () => {
      gestureHandler.getCleanupFunction()();
    };
  }, [isGameComplete]);

  const generateBubbles = () => {
    const defaultAmounts = gameDefaultAmounts;
    let remainingAmount = 1.0;
    const generatedValues: number[] = [];

    for (let i = 0; i < 39; i++) {
      let validValues = defaultAmounts.filter((value) => value <= remainingAmount && remainingAmount - value >= 0.01 * (38 - i));

      if (validValues.length === 0) {
        validValues = [Math.min(...defaultAmounts.filter((value) => value <= remainingAmount))];
      }

      const value = validValues[Math.floor(Math.random() * validValues.length)];
      generatedValues.push(value);
      remainingAmount = parseFloat((remainingAmount - value).toFixed(2));
    }

    generatedValues.push(parseFloat(remainingAmount.toFixed(2)));

    for (let i = generatedValues.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [generatedValues[i], generatedValues[j]] = [generatedValues[j], generatedValues[i]];
    }

    const bubbleArray = Array(49).fill({ value: null, revealed: false });
    let j = 0;
    for (let i = 0; i < 49; i++) {
      if (!emptyBubble.includes(i)) {
        bubbleArray[i] = { value: generatedValues[j], revealed: false };
        j++;
      }
    }

    setBubbles(bubbleArray);
  };

  useEffect(() => {
    if (total.toFixed(2) === oneDollar) {
      const timeoutId = setTimeout(() => {
        setIsGameComplete(true);
        stopGameMusic();
        collectCoins();
      }, timeout.halfSecond);

      return () => clearTimeout(timeoutId);
    }
  }, [total]);

  const revealBubble = (index: number) => {
    if (bubbles[index].revealed || bubbles[index].value === null) return;

    setBubbles((prevBubbles) => {
      const newBubbles = prevBubbles.map((bubble, i) => (i === index ? { ...bubble, beingRevealed: true, revealed: true } : bubble));
      return newBubbles;
    });

    setTotal((prevTotal) => {
      const newTotal = parseFloat((prevTotal + (bubbles[index].value as number)).toFixed(2));
      return newTotal;
    });

    if (bubbles.filter((b) => b.revealed).length === 39) {
      playLastBubbleSound();
    } else {
      playMoneySound();
    }

    setTimeout(() => {
      setBubbles((prevBubbles) => {
        const newBubbles = prevBubbles.map((bubble, i) => (i === index ? { ...bubble, beingRevealed: false } : bubble));
        return newBubbles;
      });
    }, 50);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    if (!instructionModal) {
      startCoinDrop();
      startGameMusic();
      setInstructionModal(true);
    }
  };

  const [startCoinDrop, { data: startCoinDropData }] = useMutation<StartCoinDropData>(START_COIN_DROP, {
    variables: {
      campaignId: brandsData?.sampleEligibleBrands?.campaign.id,
      userIdentifier: getLocalStorage("userId") ? getLocalStorage("userId") : getLocalStorage("generatedUserId"),
      gameType: gameType.bubblePop,
      brandId: brandsData?.sampleEligibleBrands?.brand.id,
      web: true
    }
  });

  const [collectCoins, { data: collectCoinsData }] = useMutation<
    any,
    {
      coinDropId: number;
      coinsCollected: number;
      silverCoinsCollected: number;
      goldCoinsCollected: number;
      redCoinsCollected: number;
      purpleCoinsCollected: number;
      secret?: string;
      referrerUserId?: string;
      secondaryReferrerUserId?: string;
    }
  >(COLLECT_COINS, {
    variables: {
      coinDropId: Number(startCoinDropData?.startCoinDrop?.coinDrop?.id),
      coinsCollected: 0,
      silverCoinsCollected: 100,
      goldCoinsCollected: 0,
      redCoinsCollected: 0,
      purpleCoinsCollected: 0
    },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    if (collectCoinsData) {
      setLocalStorage("coinDropId", collectCoinsData?.collectCoins?.coinDrop?.id);
      setLocalStorage("gamePlayCount", getLocalStorage("gamePlayCount") + 1);
    }
  }, [collectCoinsData]);

  return {
    bubbles,
    total,
    isGameComplete,
    isModalOpen,
    revealBubble,
    closeModal,
    instructionModal,
    setInstructionModal,
    setIsModalOpen
  };
};

export default useGameBubblePop;
