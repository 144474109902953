import React from "react";
import ImpactBadge from "../common/impactBadges/impactBadges";
import { formatNumberWithUnits, generateCloudinaryImageUrl, getStyles } from "../../utils/common-function";
import { UserCardProps } from "./userCard.types";
import ShareIcon from "./../../assets/images/shareIcon.svg";
import VerifiedIcon from "../svgImages/verifiedIcon";
import preview from "../../assets/images/preview.png";

const UserCard: React.FC<UserCardProps> = (props: UserCardProps) => {
  const impactScore = formatNumberWithUnits(props.user?.impactScore || 0);

  const openShareModal = () => {
    props.setIsShareModalOpen && props.setIsShareModalOpen(true);
  };

  const styles = getStyles(props.impactScoreLevel);

  return (
    <div className="relative w-full flex flex-col items-center">
      <div className="relative w-full h-248 max-h-248">
        {!props.showIcons && (
          <div className="absolute w-full top-3 flex justify-center z-10">
            <img src={preview} alt="Preview" className="shadow-lg" />
          </div>
        )}
        <img
          src={generateCloudinaryImageUrl(props.user?.profilePhoto || "", "750", "496")}
          alt={props.user?.fullName}
          className="w-full h-248 max-h-248 object-cover"
        />
        <div className="absolute top-2 h-full w-full bg-gradient-to-t from-black from-5% via-black/70 via-20% to-black/1 to-90%" />
      </div>
      <div className="text-center">
        {props.showIcons && (
          <>
            <span className="absolute top-3 left-0">
              <ImpactBadge
                impact={impactScore}
                isImpactIconRequired
                setIsUserProfileModalOpen={props.setIsUserProfileModalOpen}
                impactScoreLevel={props.impactScoreLevel}
              />
            </span>
            <div className="p-2 bg-black/40 rounded-full flex justify-center items-center absolute top-3 right-3 backdrop-filter-blur">
              <img src={ShareIcon} alt={ShareIcon} onClick={() => openShareModal()} />
            </div>
          </>
        )}
        <div className="w-full h-9 py-2 pb-1 left-0 top-[212px] absolute justify-center items-end gap-1 inline-flex">
          <h1
            className="break-all w-fit text-28px font-primary font-extrabold z-10 px-2 -mt-10 items-start inline-block  capitalize leading-tight-29.54"
            style={{ color: styles.levelColor }}>
            {props.user?.fullName}
            {props.user?.isPublicProfile && (
              <VerifiedIcon color={styles.levelColor} className="ml-1.5 w-4 h-4 inline-block align-middle relative -top-2" />
            )}
          </h1>
        </div>
      </div>
    </div>
  );
};

export default UserCard;
