import React from "react";
import SponsorModal from "../../components/common/sponsorModal/sponsorModal";

interface SponsorModalProps {
  sponsorImageUrl?: string;
  brandName: string;
  impactScoreLevel?: string | null;
}

const MatchSponsor: React.FC<SponsorModalProps> = ({ sponsorImageUrl, brandName, impactScoreLevel }) => (
  <div className="justify-center items-center">
    <SponsorModal sponsorName={brandName} sponsorImageUrl={sponsorImageUrl || ""} impactScoreLevel={impactScoreLevel}/>
  </div>
);

export default MatchSponsor;
