import * as React from "react";

const MainContent: React.FC<IProps> = ({ children }) => (
  <div>{children}</div>
);

export interface IProps {
  children: React.ReactNode;
}

export default MainContent;
