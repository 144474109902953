import { ApolloProvider } from "@apollo/client";
import React from "react";
import apolloClient from "../services/apollo-client";
import { ErrorBoundary } from "react-error-boundary";
import ErrorScreen from "../components/common/errorScreen";

const App: React.FC<IProps> = ({ children }) => (
  <ApolloProvider client={apolloClient}>
    <ErrorBoundary fallback={<ErrorScreen />}>
      <div>{children}</div>
    </ErrorBoundary>
  </ApolloProvider>
);

export interface IProps {
  pending?: boolean;
  children: React.ReactNode;
}

export default App;
