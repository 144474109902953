import React, { useRef, useState } from "react";
import { CampaignDetailsProps } from "./campaignDetails.types";
import SocialMediaButtons from "../common/social-media-buttons/social-media-buttons";
import VolumeFull from "./../../assets/images/volume-full.svg";
import VolumeMute from "./../../assets/images/Mute.svg";
import { formatUrlWithProtocol, generateCloudinaryImageUrl, generateCloudinaryVideoUrl, getColor, getStyles } from "../../utils/common-function";

const CampaignDetails: React.FC<CampaignDetailsProps> = (campaignDetailsProps: CampaignDetailsProps) => {
  const { campaign } = campaignDetailsProps;
  const videoRef = useRef<HTMLVideoElement>(null);
  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const [muted, setMuted] = useState(true);

  const styles = getStyles(campaignDetailsProps.impactScoreLevel);
  const lightColorStyles = getColor(campaignDetailsProps.impactScoreLevel);

  return (
    <div className="w-full text-white rounded-2xl overflow-hidden shadow-lg bg-black">
      <div className="relative">
        {campaign?.videoUrl ? (
          <div className="relative w-full h-[244px]">
            <video
              ref={videoRef}
              src={generateCloudinaryVideoUrl(campaign?.videoUrl ?? "",  "686", "488")}
              muted={muted}
              playsInline
              className="w-full h-[244px] rounded-xl object-cover"
              autoPlay
              loop
            />
          </div>
        ) : (
          <div className="relative w-full h-[244px]">
            <img
              src={generateCloudinaryImageUrl(campaign?.imageUrl || "", "686", "488")}
              alt="campaign image"
              className="w-full h-[244px] rounded-xl rounded-tl-3xl object-cover"
            />
          </div>
        )}

        <div
          className="absolute -top-2 -left-2 w-20 h-20 rounded-full"
          style={{ backgroundImage: `linear-gradient(to top,${styles.levelColor}, ${styles.levelColor})` }}>
          <div className="bg-black/85 w-20 h-20 rounded-full p-2">
            <img
              src={generateCloudinaryImageUrl(campaign?.charity?.imageUrl || "", "120", "120")}
              alt="Logo"
              className="w-full h-full rounded-full"
            />
          </div>
        </div>
        {campaign?.videoUrl && (
          <div className="absolute top-2 right-2 rounded-full flex justify-center items-center p-2 bg-black/30 backdrop-filter-blur">
            <img
              src={muted ? VolumeMute : VolumeFull}
              alt={VolumeFull}
              onClick={() => {
                setMuted(!muted);
              }}
            />
          </div>
        )}
      </div>

      <div className="rounded-b-xl" style={{ background: styles.levelColor }}>
        <div className="bg-black/80">
          <div className="rounded-b-xl" style={{ background: styles.levelColor }}>
            <div className="bg-black/75 p-4 pt-4 -mt-2 rounded-b-xl">
              <div className="font-primary text-neutral-50 text-base font-bold tracking-tight">{campaign?.title}</div>
              <div
                ref={descriptionRef}
                className={`self-stretch text-base-white/90 pt-2 text-neutral-50 text-sm font-normal font-secondary leading-tight-18.2`}>
                {campaign?.description}
              </div>

              <div className="flex space-x-4 mt-6 mb-4">
                {campaign?.charity?.website && (
                  <button
                    onClick={() => window.open(formatUrlWithProtocol(campaign?.charity?.website), "_blank")}
                    className="border text-sm font-primary font-semibold py-1 px-4 rounded-lg"
                    style={{ borderColor: lightColorStyles.levelLightColor, color: lightColorStyles.levelLightColor }}>
                    Website
                  </button>
                )}
                {campaign?.charity?.volunteerUrl && (
                  <button
                    onClick={() => window.open(formatUrlWithProtocol(campaign?.charity?.volunteerUrl), "_blank")}
                    className="border text-sm font-primary font-semibold py-1 px-4 rounded-lg"
                    style={{ borderColor: lightColorStyles.levelLightColor, color: lightColorStyles.levelLightColor }}>
                    Volunteer!
                  </button>
                )}
              </div>
              <div className="flex justify-between items-center mt-4">
                <SocialMediaButtons
                  instagramUsername={campaign?.charity?.instagramUsername}
                  tiktokUsername={campaign?.charity?.tiktokUsername}
                  youtubeUsername={campaign?.charity?.youtubeUsername}
                  facebookUsername={campaign?.charity?.facebookUsername}
                  twitterUsername={campaign?.charity?.twitterUsername}
                  linkedinUrl={campaign?.charity?.linkedinUrl}
                  impactScoreLevel={campaignDetailsProps.impactScoreLevel}
                />
              </div>
            </div>
          </div>

          <div className="p-4">
            <span className="text-base text-base-white/80 font-primary border border-base-white/80 rounded-3xl px-4 py-1">
              #{campaign?.cause?.name}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignDetails;
