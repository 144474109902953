export class GestureHandler {
  private targetElement: HTMLElement | null;

  constructor(targetElement: HTMLElement | null) {
    this.targetElement = targetElement;
  }

  private handlePreventSwipeRightGesture = (event: TouchEvent): void => {
    console.log("Preventing back gesture...");
    const touch = event.touches[0];
    // Check if the touch point is near the edge
    if (touch.pageX > 70 && touch.pageX < window.innerWidth - 50) return;
    // Prevent the default action if near the edge
    event.preventDefault();
  };

  public attachPreventSwipeRightListeners(): void {
    this.targetElement?.addEventListener("touchstart", this.handlePreventSwipeRightGesture);
  }

  public detachPreventSwipeRightListeners(): void {
    console.log("detac the back listners");
    this.targetElement?.removeEventListener("touchstart", this.handlePreventSwipeRightGesture);
  }

  public getCleanupFunction(): () => void {
    console.log("clean up the listners");
    return () => {
      this.detachPreventSwipeRightListeners();
    };
  }
}
