import React, { useEffect } from "react";
import stashrunLogo from "./../../../assets/images/StashRun-logo.svg";
import BetaLogo from "./../../../assets/images/Beta-logo.svg";
import HamburgerMenu from "../../../components/hamburgerMenu/hamburgerMenu";
import HamburgerIcon from "./../../../assets/images/hamburger.svg";
import userIcon from "./../../../assets/images/userIcon.svg";
import { useHeader } from "./useHeader";
import ProfileMenu from "../../../components/profileMenu/profileMenu";
import { staticStashlinkId } from "../../../utils/constants";
import useStashLinkHooks from "../../../modules/campaign/hooks/useStashLink";
import useStashlinkExist from "../../../services/hooks/useStashlinkExist";
import { generateCloudinaryImageUrl } from "../../../utils/common-function";

const MainHeader: React.FC = () => {
  const {
    isUserOnAuthRoute,
    profileImage,
    onClickUserIcon,
    isHamburgerOpen,
    toggleHamburgerMenu,
    closeHamburgerMenu,
    isProfileMenuOpen,
    closeProfileMenu,
    onClickStashrunLogo
  } = useHeader();

  const hamburgerMenuClass = isUserOnAuthRoute ? "h-6 invisible" : "h-6";

  const { getStashLink, stashlink } = useStashLinkHooks();
  const campaign = stashlink?.campaign;
  const { stashlinkExistData } = useStashlinkExist(campaign?.id || "");
  const isStashed = campaign?.currentAmount != campaign?.goalAmount && !stashlinkExistData;

  useEffect(() => {
    if(isUserOnAuthRoute) return;
    getStashLink({
      variables: {
        stashlinkId: staticStashlinkId
      }
    });
  }, [getStashLink]);

  return (
    <nav className="sticky top-0 w-full flex justify-between items-center p-4 py-14 bg-black text-white min-h-12 z-50">
      <button className="text-white text-4xl" onClick={toggleHamburgerMenu}>
        <img src={HamburgerIcon} alt={HamburgerIcon} className={hamburgerMenuClass} />
      </button>
      <div className="relative">
        <img src={stashrunLogo} alt={stashrunLogo} className="h-[18px] w-auto" onClick={onClickStashrunLogo} />
        <img src={BetaLogo} alt={BetaLogo} className="h-[8px] w-auto absolute top-0 -right-[28px]" onClick={onClickStashrunLogo} />
      </div>
      {!isUserOnAuthRoute ? (
        profileImage ? (
          <div className="w-7 h-[29px] relative bg-primary-l1 rounded-full">
            <img
              src={generateCloudinaryImageUrl(profileImage, "56", "56")}
              alt={userIcon}
              className="w-[25px] h-[25px] bottom-px rounded-full object-cover absolute left-2/4 -translate-x-2/4 profile-image"
              onClick={onClickUserIcon}
            />
          </div>
        ) : (
          <img src={userIcon} alt={userIcon} className="w-6 h-6 rounded-full object-cover user-icon" onClick={onClickUserIcon} />
        )
      ) : (
        <img src={userIcon} alt={userIcon} className="w-6 h-6 rounded-full object-cover user-icon invisible" onClick={onClickUserIcon} />
      )}

      {isHamburgerOpen && <HamburgerMenu onClose={closeHamburgerMenu} isStashed={isStashed} />}
      {isProfileMenuOpen && <ProfileMenu onClose={closeProfileMenu} />}
    </nav>
  );
};

export default MainHeader;
