import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import LoadingVideo from "../../components/loadingVideo/loadingVideo";
import Button from "../../components/common/button";
import { IoIosArrowBack } from "react-icons/io";
import useCreateColink from "./hooks/useCreateColink";
import { Stashlink } from "../../types";
import { generateCloudinaryImageUrl, getLocalStorage } from "../../utils/common-function";
import useUser from "../../services/hooks/useUser";
import Loader from "../../components/common/loader/loader";

const ColinkConfirmation: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const userId = getLocalStorage("userId") as string;
  const { userData } = useUser(userId);
  const videoRef = useRef<HTMLVideoElement>(null);

  const { file, thankyouMessage, isCreateStashlink } = location.state as {
    file: Blob;
    thankyouMessage: string;
    isCreateStashlink: boolean;
  };
  const [videoLoading, setVideoLoading] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  let videoURL = "";
  if (file) {
    videoURL = URL.createObjectURL(file);
  }
  const stashlink = getLocalStorage("stashlink") as Stashlink;
  const parentStashlinkId = isCreateStashlink ? null : stashlink.parentStashlink?.id || stashlink.id;
  const campaignId = stashlink.campaign.id;
  const { createColink, loading } = useCreateColink(thankyouMessage, file, parentStashlinkId, campaignId);

  useEffect(() => {
    if (videoURL) {
      setVideoLoading(true);
      setTimeout(() => {
        setVideoLoading(false);
      }, 3000);
    }
  }, []);

  if (loading.loading)
    return (
      <div className="h-screen w-screen flex justify-center items-center  bg-soft-black">
        <Loader />
      </div>
    );

  return videoLoading ? (
    <LoadingVideo />
  ) : (
    <div className="bg-soft-black h-[calc(100vh-155px)] flex flex-col items-center">
      <div className="w-full h-[calc(100%-137px)] absolute">
        {videoURL ? (
          <video ref={videoRef} className="w-full h-full object-cover" autoPlay loop playsInline>
            <source src={videoURL} type="video/mp4" />
            <div className="w-full h-10  bg-gradient-to-t from-soft-black to-soft-black/0"></div>
            Your browser does not support the video tag.
          </video>
        ) : (
          <>
            <img
              src={generateCloudinaryImageUrl(userData?.profilePhoto || "", "750", "930")}
              alt={userData?.fullName}
              className="w-full h-full object-cover"
            />
          </>
        )}
        <div className="absolute top-0 left-0 w-full p-4 py-8 bg-gradient-to-t from-soft-black/0 to-soft-black flex items-center justify-between">
          <IoIosArrowBack className="w-6 h-6 cursor-pointer absolute left-4 z-10 text-grey" onClick={() => history.goBack()} />
          <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
            <h2 className="text-white text-xl">How&apos;s that look?</h2>
          </div>
        </div>
      </div>

      <div className="fixed w-full bottom-0 left-0">
        <p className="break-all bg-gradient-to-t from-soft-black from-20% via-black/70 via-60% to-black/0 to-100% text-white text-base leading-tight-19 text-center w-full p-4 pb-7 [text-shadow:_2px_2px_0_rgb(0_0_0_/_60%)]">
          {thankyouMessage}
        </p>
        <div className="flex items-start px-6 pb-8 p-4 bg-soft-black">
          <input type="checkbox" id="tos-agreement" className="border border-grey" onChange={(e) => setIsChecked(e.target.checked)} />
          <label htmlFor="tos-agreement" className="text-grey text-10px ps-2 font-medium font-secondary">
            The content of my {videoURL ? "video" : "message"} adheres to the{" "}
            <a href="/terms" target="_blank" className="text-[#5551FF]">
              TOS{" "}
            </a>
            and <a className="text-[#5551FF]">Community Guidelines</a>.
          </label>
        </div>
        <div className="bg-black backdrop-blur-8 px-3 py-3 pb-4 z-10 h-20">
          <Button title="Create stashlink + Share" isDisabled={videoLoading || !isChecked} onClick={createColink} />
        </div>
      </div>
    </div>
  );
};

export default ColinkConfirmation;
