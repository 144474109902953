import { useState } from "react";

const useInput = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState: boolean) => !prevState);
  };

  return {
    isPasswordVisible,
    togglePasswordVisibility
  };
};

export default useInput;
