import { useLazyQuery } from "@apollo/client";
import { FETCH_CAMPAIGN_PROFILE, SAMPLE_ELIGIBLE_BRANDS } from "../../../queries";
import { CampaignData, SampleEligibleBrandsData } from "../../../types";
import { FETCH_NUM_ATTEMPTS_TO_SUPPORT_CAMPAIGN } from "../../../queries/fetch-num-attemptes-to-support-campaign";

const useCampaign = () => {
  const [fetchEligibleBrands, { loading: eligibleBrandsLoading, error: eligibleBrandsError, data: eligibleBrandsData }] =
    useLazyQuery<SampleEligibleBrandsData>(SAMPLE_ELIGIBLE_BRANDS, {
      fetchPolicy: "cache-first"
    });

  const [fetchCampaignProfile, { data: campaignProfileData, loading: campaignProfileLoading, error: campaignProfileError }] =
    useLazyQuery<CampaignData>(FETCH_CAMPAIGN_PROFILE, {
      fetchPolicy: "cache-first"
    });

  const [fetchCampaignAttempts, { data: campaignAttemptsData, loading: campaignAttemptsLoading, error: campaignAttemptsError }] =
    useLazyQuery<{ numAttemptsToSupportCampaign: number }>(FETCH_NUM_ATTEMPTS_TO_SUPPORT_CAMPAIGN, {
      fetchPolicy: "network-only"
    });

  if (eligibleBrandsError || campaignProfileError || campaignAttemptsError) {
    throw new Error();
  }

  return {
    fetchEligibleBrands,
    fetchCampaignProfile,
    fetchCampaignAttempts,
    eligibleBrandsLoading,
    eligibleBrandsError,
    eligibleBrandsData,
    campaignProfileData,
    campaignProfileLoading,
    campaignAttemptsData,
    campaignAttemptsLoading
  };
};

export default useCampaign;
