import { useState, useEffect } from "react";
import useUser from "../hooks/useUser";
import { getCookie, getLocalStorage } from "../../../utils/common-function";

const useAuthStatus = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const [userId, setUserId] = useState<string | null>(getLocalStorage("userId"));
  const { userData: currentUser, fetchUser } = useUser();

  // Sync userId state with localStorage continuously
  useEffect(() => {
    const syncUserId = () => {
      const storedUserId = getLocalStorage("userId");
      if (storedUserId !== userId) {
        setUserId(storedUserId);
      }
    };

    const intervalId = setInterval(syncUserId, 1000);

    return () => clearInterval(intervalId);
  }, [userId]);

  useEffect(() => {
    const token = getCookie("token");
    if (!token) {
      setIsUserLoggedIn(false);
    } else {
      setIsUserLoggedIn(true);
      if (userId) {
        fetchUser({ variables: { userId } });
      }
    }
  }, [fetchUser, userId]);

  return { isUserLoggedIn, currentUser };
};

export default useAuthStatus;
