import { useCallback, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../../../routes/routes.constant";
import { useAuth } from "../../../../store/contextAPI/authContext";
import { getLocalStorage, validateName } from "../../../../utils/common-function";
import useUser from "../../../../services/hooks/useUser";

const useEnterYourName = () => {
  const history = useHistory();
  const location = useLocation();
  const { user, setNameContext } = useAuth();
  const userId = getLocalStorage("userId");
  const { updateUserProfile } = useUser(userId);
  const [firstName, setFirstName] = useState<string>(user.firstName);
  const [lastName, setLastName] = useState<string>(user.lastName);
  const [focusField, setFocusField] = useState("");
  const [error, setError] = useState<string | null>(null);

  const onChangeFirstName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filteredValue = value.replace(/[^a-zA-Z-]/g, "").slice(0, 35);
    setFirstName(filteredValue.trim());
  }, []);

  const handleFocusField = useCallback((fieldName: string) => {
    setFocusField(() => fieldName);
  }, []);

  const onChangeLastName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filteredValue = value.replace(/[^a-zA-Z]/g, "").slice(0, 35);
    setLastName(filteredValue.trim());
  }, []);

  const onUpdateProfileSuccess = useCallback(() => {
    history.push({ pathname: routes.userDob, state: location.state });
  }, [history]);

  const onUpdateProfileError = useCallback(() => {
    setError("An error occurred while updating your profile. Please try again.");
  }, []);

  const onClickContinueHandler = useCallback(async () => {
    setNameContext(firstName, lastName);
    await updateUserProfile({ fullName: firstName + " " + lastName }, onUpdateProfileSuccess, onUpdateProfileError);
  }, [firstName, history, lastName, setNameContext, location]);

  const isContinueDisable = useMemo(() => !(validateName(firstName) && validateName(lastName)), [firstName, lastName]);

  return {
    firstName,
    lastName,
    error,
    focusField,
    handleFocusField,
    onChangeFirstName,
    onChangeLastName,
    isContinueDisable,
    onClickContinueHandler
  };
};

export default useEnterYourName;
