import { useState, useEffect } from "react";

const ProgressBar = ({ percentageCompleted }: { percentageCompleted: number }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setProgress(percentageCompleted);
    }, 100); // Optional delay before starting the animation

    return () => clearTimeout(timeout); // Cleanup the timeout on component unmount
  }, [percentageCompleted]);

  return (
    <div className="bg-grey-bg rounded-full h-2">
      <div className="h-2 bg-primary-l1 rounded-full transition-all duration-[5000ms]" style={{ width: `${progress}%` }} />
    </div>
  );
};

export default ProgressBar;
