import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { getCookie } from "../utils/common-function";
import {envVariables} from "../common/constants";

const uploadLink = createUploadLink({
  uri: envVariables.apiEndPoint
}) as unknown as ApolloLink;

const authLink = new ApolloLink((operation, forward) => {
  const token = getCookie("token");
  operation.setContext({
    headers: {
      Authorization: token ? `Bearer ${token}` : ""
    }
  });
  return forward(operation);
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink]),
  cache: new InMemoryCache()
});

export default apolloClient;
