import React, { useState } from "react";
import UserCard from "../../components/userCard/userCard";
import PlayToFund from "../../components/playToFundCard/playToFund";
import CampaignDetails from "../../components/campaignDetails/campaignDetails";
import Loader from "../../components/common/loader/loader";
import MatchSponsor from "./matchSponsor";
import useCampaignProfile from "./hooks/useCampaignProfile";
import BottomDrawer from "../../components/common/modal/BottomDrawer";
import UserProfile from "../../components/userProfile/userProfile";
import ShareComponent from "../../components/shareModal/shareModal";
import ColinkUsersCard from "../../components/colinkUsersCard/colinkUsersCard";
import GoalReached from "../../components/goalReached/goalReached";
import useFollowEntity from "../../services/hooks/useFollowEntity";
import useFollowingEntity from "../../services/hooks/useFollowingEntity";
import useAuthStatus from "./hooks/useAuthStatus";
import useCampaignFunded from "./hooks/useCampaignFunded ";
import useCurrentUserStashLink from "./hooks/useCurrentUserStashLink";
import { getImpactLevel, getStyles, setLocalStorage } from "../../utils/common-function";
import { useImpactScoreModal } from "../../services/hooks/useImpactScoreModal";
import ImpactScoreModal from "../../components/impactScoreModal/impactScoreModal";
import { useHistory } from "react-router";
import { routes } from "../../routes/routes.constant";
import { useAuth } from "../../store/contextAPI/authContext";
import { defaultCoLinkUserLevel } from "../../utils/constants";
import useCheckStashLink from "./hooks/useCheckStashLink";

const CampaignProfile: React.FC = () => {
  const { eligibleBrandsData, eligibleBrandsLoading, isMatchSponsor, fetchBrands, stashlink, stashlinkLoading, campaignAttemptsLoading } =
    useCampaignProfile();
  const user = stashlink?.user;
  const navigation = useHistory();
  const { user: userAuth } = useAuth();
  const campaign = stashlink?.campaign;
  const { isUserLoggedIn, currentUser } = useAuthStatus();
  const isCampaignFunded = useCampaignFunded(campaign);
  const { isImpactScoreModalOpen, setIsImpactScoreModalOpen, impactScoreLevel } = useImpactScoreModal(currentUser?.user);
  const isCurrentUserStashLink = useCurrentUserStashLink(currentUser?.user.id, user?.id);
  const [isUserProfileModalOpen, setIsUserProfileModalOpen] = useState<boolean>(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [isGoalReachedModalOpen, setIsGoalReachedModalOpen] = useState<boolean>(true);
  const [changeStatusFollowing, setChangeStatusFollowing] = useState(false);
  const { isUserFollowing, refetch } = useFollowingEntity(currentUser?.user.id || "", user?.id || "");
  const { followCelebrity } = useFollowEntity(user?.id || "", refetch);
  setLocalStorage("stashlink", stashlink);
  useCheckStashLink(stashlinkLoading, stashlink);

  const currentUrl = window.location.href;
  const stashUser = {
    profilePhoto: user?.profilePhoto,
    fullName: user?.fullName,
    isPublicProfile: user?.isPublicProfile
  };

  let parentUser = null;
  if (stashlink?.parentStashlink) {
    parentUser = stashlink?.parentStashlink.user;
  }

  const stashLinkUserImpactScoreLevel = parentUser ? defaultCoLinkUserLevel : user?.impactScore ? getImpactLevel(user?.impactScore) : "1";

  if (eligibleBrandsLoading || stashlinkLoading || campaignAttemptsLoading)
    return (
      <div className="h-[calc(100vh-48px)] w-screen flex justify-center items-center bg-soft-black">
        <Loader />
      </div>
    );

  const closeUserProfileModal = () => {
    setIsUserProfileModalOpen(false);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  const closeGoalReachedModal = () => {
    setIsGoalReachedModalOpen(false);
  };

  const handleFollowClick = async () => {
    !userAuth.isProfileComplete && navigation.push({ pathname: routes.callback, state: { from: location.pathname } });
    await followCelebrity(true);
    setChangeStatusFollowing(true);
  };

  const styles = getStyles(stashLinkUserImpactScoreLevel);

  return (
    <>
      <div className="bg-black text-white min-h-screen flex flex-col items-center pb-32">
        {parentUser ? (
          <ColinkUsersCard
            parentUser={parentUser}
            colinkUser={user}
            isShareIconRequired={true}
            setIsShareModalOpen={setIsShareModalOpen}
            impactScoreLevel={stashLinkUserImpactScoreLevel}
          />
        ) : (
          <UserCard
            user={user}
            setIsUserProfileModalOpen={setIsUserProfileModalOpen}
            setIsShareModalOpen={setIsShareModalOpen}
            showIcons={true}
            impactScoreLevel={stashLinkUserImpactScoreLevel}
          />
        )}

        <div
          className={`w-full`}
          style={{
            background: `linear-gradient(to top, ${styles.levelColor} 30%, ${styles.levelColor} 85%, black)`
          }}>
          <div className="bg-black/85 p-4 pb-12 pt-1">
            <div className="h-[49px] w-full flex-col justify-center items-center gap-2 inline-flex z-10">
              <div className="self-stretch h-[49px] flex-col justify-start items-center gap-1 flex">
                <div className="text-neutral-50 text-base font-normal font-secondary leading-tight">is stashing for</div>
                <div className="self-stretch text-center text-neutral-50 text-[22px] font-bold font-primary leading-normal tracking-tight">
                  {campaign?.charity?.name}
                </div>
              </div>
            </div>
            <div className="mt-5">
              <CampaignDetails campaign={campaign} impactScoreLevel={stashLinkUserImpactScoreLevel} />
            </div>
          </div>
        </div>
        <div className="sticky bottom-0 w-full">
          <PlayToFund
            campaign={campaign}
            userProfile={parentUser?.profilePhoto || user?.profilePhoto}
            celebrityName={parentUser?.fullName || user?.fullName}
            onToggle={fetchBrands}
            isUserLoggedIn={isUserLoggedIn}
            isCampaignFunded={isCampaignFunded}
            followCelebrity={handleFollowClick}
            isUserFollowing={isUserFollowing}
            isCurrentUserStashLink={isCurrentUserStashLink}
            setIsShareModalOpen={setIsShareModalOpen}
          />
        </div>
        {isMatchSponsor && (
          <div className="h-[calc(100vh-48px)] w-screen flex flex-col">
            <MatchSponsor
              sponsorImageUrl={eligibleBrandsData?.sampleEligibleBrands?.brand.imageUrl}
              brandName={eligibleBrandsData?.sampleEligibleBrands?.brand.name || ""}
              impactScoreLevel={stashLinkUserImpactScoreLevel}
            />
          </div>
        )}
        {isUserProfileModalOpen && (
          <BottomDrawer isOpen={isUserProfileModalOpen} onClose={closeUserProfileModal}>
            <UserProfile user={user} impactScoreLevel={stashLinkUserImpactScoreLevel} />
          </BottomDrawer>
        )}
        {!isCurrentUserStashLink && isCampaignFunded && isGoalReachedModalOpen && (
          <GoalReached
            user={parentUser || user}
            campaign={campaign}
            isUserLoggedIn={isUserLoggedIn}
            onClose={closeGoalReachedModal}
            isCampaignFunded={isCampaignFunded}
            isUserFollowing={isUserFollowing}
            followCelebrity={handleFollowClick}
            changeStatusFollowing={changeStatusFollowing}
          />
        )}
        {isImpactScoreModalOpen && (
          <ImpactScoreModal
            user={currentUser?.user}
            impactScoreLevel={impactScoreLevel}
            setIsImpactScoreModalOpen={setIsImpactScoreModalOpen}
          />
        )}
      </div>

      {isShareModalOpen && (
        <ShareComponent
          stashUser={parentUser || stashUser}
          charityName={campaign?.charity?.name}
          charityLogo={campaign?.charity?.imageUrl}
          currentAmount={campaign?.currentAmount}
          goalAmount={campaign?.goalAmount}
          shareUrl={currentUrl}
          coLinkUser={parentUser ? user : undefined}
          isOpen={isShareModalOpen}
          onClose={closeShareModal}
          impactScoreLevel={stashLinkUserImpactScoreLevel}
        />
      )}
    </>
  );
};

export default CampaignProfile;
