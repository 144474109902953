import React from "react";
import { ITabWrapperProps } from "../containers/types";

const TabWrapper: React.FC<ITabWrapperProps> = ({ activeIndex, totalTabs }) => (
  <div className="flex justify-around items-center px-1 py-3 bg-soft-black">
    {Array.from({ length: totalTabs }, (_, index) => (
      <div key={index} className={`h-1 w-full rounded-full mx-1 ${index <= activeIndex ? "bg-grey" : "bg-grey-op70 opacity-30"}`} />
    ))}
  </div>
);

export default TabWrapper; 
