const InstaGramIcon = ({ color = "#FAFAFA", width = "28", height = "29", className = "" }) => (
  <svg className={className} width={width} height={height} viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3.5" y="4.30811" width="21" height="21" rx="5.83333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M19.7742 8.64027C19.5572 8.64143 19.381 8.8176 19.381 9.0346C19.381 9.2516 19.5583 9.42777 19.7753 9.42777C19.9923 9.42777 20.1685 9.2516 20.1685 9.0346C20.1697 8.81643 19.9923 8.64027 19.7742 8.64027"
      stroke={color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9687 11.8381C18.6089 13.4783 18.6089 16.1376 16.9687 17.7778C15.3285 19.418 12.6692 19.418 11.029 17.7778C9.38878 16.1376 9.38878 13.4783 11.029 11.8381C12.6692 10.1979 15.3285 10.1979 16.9687 11.8381"
      stroke={color}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default InstaGramIcon;
