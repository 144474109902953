import React, { useEffect } from "react";
import Button from "../../components/common/button";
import CampaignDetails from "../../components/campaignDetails/campaignDetails";
import ColinkUsersCard from "../../components/colinkUsersCard/colinkUsersCard";
import ColinkModal from "../../components/coLinkModal/colinkModal";
import { convertToPercentage, getImpactLevel, getLocalStorage, getStyles } from "../../utils/common-function";
import { Stashlink } from "../../types";
import { useHistory, useLocation } from "react-router";
import UserCard from "../../components/userCard/userCard";
import useUser from "../../services/hooks/useUser";
import { useImpactScoreModal } from "../../services/hooks/useImpactScoreModal";
import ImpactScoreModal from "../../components/impactScoreModal/impactScoreModal";
import useColinkExist from "../../services/hooks/useColinkExist";
import useStashlinkExist from "../../services/hooks/useStashlinkExist";
import Loader from "../../components/common/loader/loader";
import { defaultCoLinkUserLevel } from "../../utils/constants";
import { formatCurrencyToUsd } from "../../utils/format";
import ProgressBar from "../../components/common/progressBar";
import { routes } from "../../routes/routes.constant";

const ColinkCreation: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const state = location.state as { from: string; param?: any };
  const isCreateStashlink = state?.param?.isCreateStashLink;
  const isNavigateBack = state?.param?.isNavigateBack;
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const stashlink = getLocalStorage("stashlink") as Stashlink;
  const userId = getLocalStorage("userId") as string;
  const { userData } = useUser(userId);
  const { isImpactScoreModalOpen, setIsImpactScoreModalOpen, impactScoreLevel } = useImpactScoreModal(userData);
  const { colinkExistLoading, colinkExistData } = useColinkExist(stashlink?.id || "");
  const { stashlinkExistLoading, stashlinkExistData } = useStashlinkExist(stashlink.campaign?.id || "");

  let parentUser = stashlink.user;
  if (stashlink.parentStashlink) {
    parentUser = stashlink.parentStashlink.user;
  }

  const stashLinkUserImpactScoreLevel = parentUser
    ? defaultCoLinkUserLevel
    : stashlink.user?.impactScore
      ? getImpactLevel(stashlink.user?.impactScore)
      : "1";

  const styles = getStyles(stashLinkUserImpactScoreLevel);
  const percentageCompleted = convertToPercentage(stashlink.campaign?.currentAmount, stashlink.campaign?.goalAmount);

  useEffect(() => {
    if (isCreateStashlink && stashlinkExistData) {
      history.push("/");
    } else if ((!isCreateStashlink && colinkExistData) || stashlink.user.id === userId) {
      history.push(isNavigateBack && (stashlink.user.id !== userId) ? routes.campaignThankYou : "/", { fromValidFlow: true });
    }
  }, [stashlinkExistData, colinkExistData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (colinkExistLoading || stashlinkExistLoading)
    return (
      <div className="h-screen w-screen flex justify-center items-center  bg-soft-black">
        <Loader />
      </div>
    );

  return (
    <div className="bg-black min-h-screen flex flex-col items-center pb-32">
      {isCreateStashlink ? (
        <UserCard user={userData} impactScoreLevel={stashLinkUserImpactScoreLevel} />
      ) : (
        <ColinkUsersCard parentUser={parentUser} colinkUser={userData} impactScoreLevel={stashLinkUserImpactScoreLevel} />
      )}
      <div
        className={`w-full`}
        style={{
          background: `linear-gradient(to top, ${styles.levelColor} 30%, ${styles.levelColor} 85%, black)`
        }}>
        <div className="bg-black/85 p-4 pb-12 pt-1">
          <div className="h-[49px] w-full flex-col justify-center items-center gap-2 inline-flex z-10">
            <div className="self-stretch h-[49px] flex-col justify-start items-center gap-1 flex">
              <div className="text-neutral-50 text-base font-normal font-secondary leading-tight">is stashing for</div>
              <div className="self-stretch text-center text-neutral-50 text-[22px] font-bold font-primary leading-normal tracking-tight">
                {stashlink?.campaign?.charity?.name}
              </div>
            </div>
          </div>
          <div className="mt-5">
            <CampaignDetails campaign={stashlink?.campaign} impactScoreLevel={stashLinkUserImpactScoreLevel} />
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 w-full bg-black/70  backdrop-blur-8 text-center py-3 px-4 flex flex-col gap-3">
        <div className="px-2 pt-1 pb-0 flex flex-col gap-[6px]">
          <ProgressBar percentageCompleted={percentageCompleted} />
          <div className="flex flex-row items-center justify-center">
            <p className="text-base font-primary font-bold text-white mr-1">{formatCurrencyToUsd(stashlink?.campaign?.currentAmount)}</p>
            <p className="text-sm font-secondary font-normal text-white">
              raised of {formatCurrencyToUsd(stashlink?.campaign?.goalAmount)}
            </p>
          </div>
        </div>
        <Button title="Personalize + Share" isDisabled={false} onClick={() => setOpenModal(true)} />
      </div>
      {openModal && <ColinkModal onClose={() => setOpenModal(false)} isCreateStashlink={isCreateStashlink} />}
      {isImpactScoreModalOpen && (
        <ImpactScoreModal user={userData} impactScoreLevel={impactScoreLevel} setIsImpactScoreModalOpen={setIsImpactScoreModalOpen} />
      )}
    </div>
  );
};

export default ColinkCreation;
